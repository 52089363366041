import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

const randomString = () => {
  return Math.random().toString(36).substr(2, 5);
};

const fairlyUnique = () => {
  return `${Date.now()}-${randomString()}`;
};

interface MultiToggleProps {
  className?: string;
  options: object;
  active: any;
  disabled?: boolean;
  onSelect?: (value: any) => void;
  theme?: "light" | "dark";
}

export default function MultiToggle({
  active,
  onSelect = () => null,
  theme = "light",
  options = {},
  disabled = false,
  className = "",
}: MultiToggleProps) {
  const id = useMemo(() => fairlyUnique(), []);
  const { t } = useTranslation();

  const handleClick = useCallback(
    (value: string) => {
      if (!disabled) {
        return onSelect(value);
      }
    },
    [disabled, onSelect]
  );

  return (
    <div
      className={`MultiToggle ${disabled ? "disabled" : ""
        } ${theme} ${className}`}
    >
      {Object.entries(options).map(([key, value]) => value ? (
        <div
          key={key}
          className={`e2e-${value}-multi-toggle-thumb MultiToggle-Thumb mr-6`}
          onClick={() => value && handleClick(key)}
          aria-disabled={disabled}
        >
          {active.charAt(0).toUpperCase() + active.slice(1) === key ? (
            <div
              className={`${active.charAt(0).toUpperCase() + active.slice(1) === key
                ? "active border-b-2 border-black pb-4"
                : ""
                }`}
            >
              {t(key)}
            </div>
          ) : (
            <div
              className={`inactive ${!value && "text-gray-500"}`}
              key={`${id}-inactive`}
            >
              {t(key)}
            </div>
          )}
        </div>
      ) : null)}
    </div>
  );
}
