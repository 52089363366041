import { useContext, useEffect, useMemo, useState } from "react";
import { DEFAULT_LANGUAGE } from "../constants.ts";
import i18n from "../i18n.ts";
import { Language, LANGUAGES } from "@lib/shared.ts";
import { useSearchParams } from "react-router-dom";
import storage, { getLocalStorageItemString } from "@lib/storage.ts";
import { AppContext } from "../context/app.tsx";

export function useLanguage() {
  return useMemo(() => {
    return i18n.language;
  }, []);
}

export function useLanguageInitialization() {
  const [searchParams] = useSearchParams();
  const { store } = useContext(AppContext);
  const [language, setLanguage] = useState(searchParams.get("language")
    || getLocalStorageItemString('language') || DEFAULT_LANGUAGE);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    const langParams = searchParams.get("language");
    if (langParams) {
      window.localStorage.setItem('language', langParams);
      if (langParams !== language) {
        i18n.changeLanguage(langParams);
        setLanguage(langParams);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if ((!language && store?.locale)
      || (language && store?.locale && !LANGUAGES.map((lang) => lang.code).includes(language))) {
      setLanguage(store?.locale);
    }
  }, [language, store?.locale])

  const setNewLanguage = (language: Language) => {
    i18n.changeLanguage(language.code);
    localStorage.setItem('language', language.code);
    setLanguage(language.code);
  }

  return [language, setNewLanguage];
}