import { motion } from 'framer-motion';
import ReactCalendar from 'react-calendar';
import './booking-calendar.css';
import ChevronIcon from '@components/icon-chevron';
import { dateString } from '@lib/datetime';
import { DateTime } from 'luxon';

export type DateSelection = Date | null;
export type DateSelectionRange = DateSelection | [DateSelection, DateSelection];

interface CalendarProps {
  value: string;
  onChange: (value: string) => void;
  onActiveStartDateChange: (value: any) => void;
  availableDays?: Array<{ bookings_available: boolean, datestring: string }>;
  locale: string;
}

export default function BookingCalendar({
  value,
  onChange,
  onActiveStartDateChange,
  availableDays = [],
  locale
}: CalendarProps) {
  const now = new Date();
  const oneYearFromNow = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());

  const handleIsDayDisabled = ({ date }: { date: Date }) => {
    // check if date is in list of available dates
    return !availableDays.find(
      ({ bookings_available, datestring }: { bookings_available: boolean, datestring: string }
      ) => {
        return datestring === dateString(date) && bookings_available;
      });
  }

  return (
    <motion.div
      key='booking-calendar'
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
    >
      <ReactCalendar
        locale={locale}
        onChange={(date) => onChange(dateString(date as Date))}
        value={DateTime.fromISO(value).toJSDate()}
        minDate={now}
        maxDate={oneYearFromNow}
        maxDetail='month'
        view='month'
        nextLabel={<NextArrow />}
        prevLabel={<PrevArrow />}
        next2Label={<></>}
        prev2Label={<></>}
        formatShortWeekday={(locale, date) => {
          return date.toLocaleDateString(locale, { weekday: 'narrow' });
        }}
        onActiveStartDateChange={onActiveStartDateChange}
        tileDisabled={handleIsDayDisabled}
      />
    </motion.div>
  );
}

function NextArrow() {
  return (
    <div
      style={{
        transform: 'rotate(90deg)'
      }}
    >
      <ChevronIcon />
    </div>
  )
}

function PrevArrow() {
  return (
    <div
      style={{
        transform: 'rotate(270deg)'
      }}
    >
      <ChevronIcon />
    </div>
  )
}