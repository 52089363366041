import { useTranslation } from "react-i18next";
import PageTransition from "@components/page-transition";

import loader from "../assets/icons/loading.png";

export default function Loading() {
  const { t } = useTranslation();

  return (
    <PageTransition
      type={{
        in: "fade",
        out: "left",
      }}
      className={
        "flex h-full animate-pulse flex-col items-center justify-center"
      }
    >
      <img src={loader} alt={t("Loading")} />
    </PageTransition>
  );
}
