interface CloseButtonProps {
  onClick: () => void;
  width?: number;
  height?: number;
  style?: Record<string, any>;
}

export default function CloseButton({ onClick, style = {}, width = 23, height = 23 }: CloseButtonProps) {
  return (
    <button
      onClick={onClick}
      className={
        "flex items-center justify-center rounded-full bg-white shadow-button"
      }
      style={{
        width: 40,
        height: 40,
        minWidth: 40,
        minHeight: 40,
        ...style
      }}
    >
      <svg
        style={{
          width,
          height,
        }}
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M44.1237 11.6914L11.6927 44.1225"
          stroke="#111111"
          strokeWidth="3.48846"
        />
        <path
          d="M11.6918 11.6914L44.1229 44.1225"
          stroke="#111111"
          strokeWidth="3.48846"
        />
      </svg>
    </button>
  );
}
