import { FetchResult } from "@apollo/client";
import { VITE_STAGE } from "../constants";
import { ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function cleanObject(obj: Record<string, number | string | null | undefined>) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export type Theme = "light" | "dark";

export const DEFAULT_TRANSITION = {
  ease: [0.4, 0, 0, 1],
  duration: 0.5,
};

export default function bookingSteps(activeIndex: number) {
  const steps = [
    {
      id: "1",
      title: "Select Date/Time",
      completed: activeIndex > 0,
    },
    {
      id: "2",
      title: "Login",
      completed: activeIndex > 1,
    },
    {
      id: "3",
      title: "Confirm Booking",
      completed: activeIndex > 2,
    },
  ];

  return {
    steps,
    activeIndex: activeIndex,
  };
}

export const normalizePhone = (
  phone: string,
  getValues?: (key: string) => string
) => {
  const phoneClean = phone.replace(/\D/g, "").replace(/[()-]/g, "");

  if (phoneClean.length > 11 && getValues) {
    return getValues("phone");
  }

  return phoneClean;
};

export function formatPhoneNumber(number: string = "") {
  number = normalizePhone(number);
  if (number.length === 0) return "";

  const cleaned = ("" + number)
    .replace(/\D/g, "")
    .replace(/[-()]/g, "");

  let countryCode;
  let firstPart = cleaned.slice(0, 3);
  let secondPart = cleaned.slice(3, 6);
  let thirdPart = cleaned.slice(6, 10);
  if (number.length >= 11) {
    countryCode = cleaned.slice(0, 1);
    firstPart = cleaned.slice(1, 4);
    secondPart = cleaned.slice(4, 7);
    thirdPart = cleaned.slice(7);
  }


  if (firstPart.length === 0) return "";
  if (secondPart.length === 0) return `${firstPart}`;
  if (thirdPart.length === 0) return `${firstPart} ${secondPart}`;

  return `${countryCode ? `${countryCode} ` : ''}${firstPart} ${secondPart}-${thirdPart}`;
}

export function currencySymbol(currency?: string) {
  switch (currency) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "JPY":
      return "¥";
    default:
      return "$";
  }
}

export const isLocalDev = VITE_STAGE === "local";

export const throwFirstError = (res: FetchResult<any>) => {
  if (res.errors) throw Error(res.errors[0]?.message);
  return res;
}

export const DEFAULT_EASE = [0.4, 0, 0, 1];

export const DEFAULT_DURATION = 1;

export const isJsonString = (str: any) => {
  if (str === null || str === undefined || typeof (str) !== 'string') {
    return false
  }

  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export interface Language {
  code: string;
  name: string;
}
export const LANGUAGES: Array<Language> = [
  {
    code: "en",
    name: 'English',
  },
  {
    code: "en-GB",
    name: 'English',
  },
  {
    code: "fr-FR",
    name: 'Français',
  },
  {
    code: "fr",
    name: 'Français',
  },
  {
    code: 'de',
    name: 'Deutsch',
  },
  {
    code: 'de-DE',
    name: 'Deutsch',
  }
]
