import { ReactNode, createContext, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom";
import {
  DEFAULT_LANGUAGE,
} from '../constants';
import { isJsonString } from "@lib/shared";
import { Experience, Store, TimeSlot } from "../data/types";
import { useStore } from "@hooks/store";
import { getLocalStorageItemString, parseValue } from "@lib/storage.ts";
import { useLanguageInitialization } from "@hooks/language.ts";
import { Language } from "@lib/shared";

interface AppContextType {
  storeId: string | null;
  experienceId: string | null;
  store: Store | null;
  experience: Experience | null;
  language: string;
  setLanguage: (language: Language) => void;
  heldTimeSlot: TimeSlot | null;
  setHeldTimeSlot: (timeSlot?: TimeSlot) => void;
  cookiesAccepted: boolean;
  acceptCookies: () => void;
}

const AppContext = createContext<AppContextType>({
  storeId: null,
  experienceId: null,
  language: DEFAULT_LANGUAGE,
  setLanguage: () => { },
  heldTimeSlot: null,
  setHeldTimeSlot: () => { },
  cookiesAccepted: false,
  acceptCookies: () => { },
  store: null,
  experience: null
});

const parseLocalStorageValue = (key: string) => {
  const value = localStorage.getItem(key)
  if (typeof value === 'string') {
    if (isJsonString(value)) {
      return JSON.parse(value)
    } else {
      return value
    }
  }

  return null
}

const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [searchParams] = useSearchParams();
  const [language, setLanguage] = useLanguageInitialization();
  const [storeId, setStoreId] = useState(searchParams.get("store_id")
    || getLocalStorageItemString('store_id'));
  const [experienceId, setExperienceId] = useState(searchParams.get("experience_id")
    || getLocalStorageItemString('experience_id'));
  const [heldTimeSlot, setHeldTimeSlot] = useState(() => {
    const timeSlot = parseLocalStorageValue('held_time_slot');
    if (timeSlot && timeSlot.expiration_date < Date.now()) {
      window.localStorage.removeItem('held_time_slot');
      return;
    }
    return timeSlot;
  });
  const { store, experience, loading } = useStore(storeId, experienceId);
  const [cookiesAccepted, setCookiesAccepted] = useState(
    window.localStorage.getItem('cookies_accepted') === 'true'
  );

  useEffect(() => {
    const storeIdParams = searchParams.get("store_id");
    if (storeIdParams) {
      window.localStorage.setItem('store_id', storeIdParams);
      if (storeIdParams !== storeId) setStoreId(storeIdParams);
    }
    const experienceIdParams = searchParams.get("experience_id");
    if (experienceIdParams) {
      window.localStorage.setItem('experience_id', experienceIdParams);
      if (experienceIdParams !== experienceId) setExperienceId(experienceIdParams);
    }
  }, [searchParams]);

  const handleSetHeldTimeSlot = (timeSlot?: TimeSlot) => {
    if (!timeSlot) {
      setHeldTimeSlot(null);
      return window.localStorage.removeItem('held_time_slot');
    }
    setHeldTimeSlot(timeSlot);
    window.localStorage.setItem('held_time_slot', JSON.stringify(timeSlot));
  }

  const acceptCookies = () => {
    setCookiesAccepted(true);
    window.localStorage.setItem('cookies_accepted', 'true');
  }

  if (loading) return null;

  return (
    <AppContext.Provider
      value={{
        storeId,
        experienceId,
        store,
        experience,
        language,
        setLanguage,
        heldTimeSlot,
        setHeldTimeSlot: handleSetHeldTimeSlot,
        cookiesAccepted,
        acceptCookies
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppContextProvider }