import { Design, Session } from "../data/types";

export function unpackDesignNodes(session: Session | null): Array<Design> {
  if (!session) {
    return [];
  }
  const { designs: { edges = [] } = {} } = session;
  return edges
    .map((edge) => {
      return edge.node;
    })
    .filter((node) => !!node);
}