import { useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect } from "react";

import { useReserveBooking } from "@hooks/session";
import { useUser } from "@hooks/user";
import Loading from "@components/loading";

import { AppContext } from "../../context/app";
import { SESSION_STATUS_TYPES } from "../../data/types";

export default function ConfirmBooking() {
  const navigate = useNavigate();
  const { heldTimeSlot } = useContext(AppContext);
  const [reserveBooking, { loading: booking }] = useReserveBooking();
  const { user, loading } = useUser();

  const hasSession = user?.next_session
    || (user?.last_session && user?.last_session?.latest_status !== SESSION_STATUS_TYPES.COMPLETED);

  const handleConfirm = useCallback(async () => {
    if (booking) return;
    if (heldTimeSlot) {
      // this handles navigation
      if (user?.nu_id) {
        await reserveBooking({ user, timeslot: heldTimeSlot });
      } else if (user?.g_id && user?.email) {
        await reserveBooking({
          guest: {
            preferred_name: user?.g_preferred_name,
            g_email: user?.email,
          },
          timeslot: heldTimeSlot
        });
      }
      return;
    }

    if (hasSession) {
      navigate("/session-manager");
    } else {
      navigate("/intro");
    }
  }, [heldTimeSlot, reserveBooking, user, navigate]);

  useEffect(() => {
    if (user && !loading) handleConfirm();
    if (!user && !loading) navigate("/intro");
  }, [handleConfirm, user, loading])

  return <Loading />;
}
