import { DateTime } from "luxon";

function getNumberSuffix(num: number) {
  const th = "th";
  const rd = "rd";
  const nd = "nd";
  const st = "st";

  if (num === 11 || num === 12 || num === 13) return th;

  const lastDigit = num.toString().slice(-1);

  switch (lastDigit) {
    case "1":
      return st;
    case "2":
      return nd;
    case "3":
      return rd;
    default:
      return th;
  }
}

export function simpleDateFormat(
  millis: number,
  timezone: string = "America/Los_Angeles",
  locale: string = "en-us",
  hideSuffix = false
) {
  const datetime = DateTime.fromMillis(millis, { zone: timezone, locale });

  const date = datetime?.toLocaleString?.({
    month: "short",
    day: "numeric",
  });

  const suffix = hideSuffix
    ? ""
    : getNumberSuffix(parseInt(datetime.toLocaleString({ day: "numeric" })));

  return `${date}${suffix}`;
}

export function simpleAmPmTimeFormat(
  millis: number,
  timezone: string = "America/Los_Angeles",
  locale: string = "en-us",
) {
  const datetime = DateTime.fromMillis(millis, { zone: timezone, locale });

  return datetime.toLocaleString(DateTime.TIME_SIMPLE);
}

export function simpleTimeAndDateFormat(
  millis: number,
  timezone: string = "America/Los_Angeles",
  locale: string = "en-us",
) {
  const datetime = DateTime.fromMillis(millis, { zone: timezone, locale });

  const datePortion = datetime?.toLocaleString?.({
    month: "short",
    day: "numeric",
  });
  const timePortion = datetime?.toLocaleString?.(DateTime.TIME_SIMPLE);

  return `${timePortion}, ${datePortion}`;
}

export function getDateTimeForDateString(
  dateString: string,
  timezone: string = "America/Los_Angeles",
) {
  const datetime = DateTime.fromISO(dateString, { zone: timezone });
  return datetime.toMillis();
}

export function dateString(date: Date = new Date()) {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
}

export function getDatetimeForTime(
  dateElements: {
    year?: number;
    month?: number;
    day?: number;
    hour?: number;
    minute?: number;
    second?: number;
    millisecond?: number;
  },
  timezone: string
) {
  const today = DateTime.fromJSDate(new Date(), {
    zone: timezone,
  }).toObject();
  const dateInZone = DateTime.fromObject(
    { ...today, ...dateElements },
    { zone: timezone }
  );
  return dateInZone.toMillis();
}

export function checkIfToday(datetime: number, timezone: string) {
  const start = getDatetimeForTime(
    {
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    },
    timezone
  );
  const end = getDatetimeForTime(
    {
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    },
    timezone
  );

  return start <= datetime && datetime <= end;
}

export function checkIfTodayDateString(date: string) {
  return date === dateString();
}
