import {
  useMutation,
  useQuery,
  DocumentNode,
  MutationHookOptions,
  QueryHookOptions,
  LazyQueryHookOptions,
  useLazyQuery,
} from "@apollo/client";
import { publicClient } from "../data/providers/apollo";

export const usePublicQuery = (
  query: DocumentNode,
  options: QueryHookOptions = {}
) => useQuery(query, { ...options, client: publicClient });

export const usePublicLazyQuery = (
  query: DocumentNode,
  options: LazyQueryHookOptions = {}
) => useLazyQuery(query, { ...options, client: publicClient });

export const usePublicMutation = (
  query: DocumentNode,
  options: MutationHookOptions = {}
) => useMutation(query, { ...options, client: publicClient });
