import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from './i18n/en/translations.json';
import frTranslation from './i18n/fr/translations.json';
import deTranslation from './i18n/de/translations.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: enTranslation
  },
  'en-GB': {
    translation: enTranslation
  },
  fr: {
    translation: frTranslation
  },
  'fr-FR': {
    translation: frTranslation
  },
  de: {
    translation: deTranslation
  },
  'de-DE': {
    translation: deTranslation
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false, // Set to true to see if any strings are not being translated
    resources,
    lng: "en-GB", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      transSupportBasicHtmlNodes: true,
    }
  });

export default i18n;