import {t} from "i18next";
import Typography from "@components/typography.tsx";
import {Info} from "./info.tsx";
import CleaningSection from "./cleaning-section.tsx";
import {ServiceListing} from "./service-listing.tsx";

export function MainSection() {
  return (
    <div className={"flex-grow flex flex-col"}>
      <Typography className={"font-medium text-nearblack"} size={24}>
        {t("Pricing")}
      </Typography>

      <CleaningSection/>
      <Info/>
      <ServiceListing/>
    </div>
  );
}
