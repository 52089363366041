import {ReactNode} from "react";
import {cn} from "@lib/shared.ts";

export default function Section({children, title, titleClass, className}: { children: ReactNode, title: string, titleClass?: string, className?: string }) {
  return (
    <div
      className={cn("flex flex-col relative sm:py-8 border flex-grow items-start rounded-xl", className)}
    >
      <div className={cn('border-b w-full text-xl py-2 px-4 font-semibold', titleClass)}>
        {title}
      </div>

      <div className={'w-full flex flex-col flex-1'}>
        {children}
      </div>
    </div>
  )
}