import {useContext} from "react";
import {AppContext} from "../context/app.tsx";
import {SESSION_TYPES, SessionType} from "../data/types.ts";

type ExperienceTypeSource = SessionType | string;
export type ExperienceType = "clean_care" | "nby";

export function useExperience() {
  const { experience } = useContext(AppContext);

  return experience;
}

export function useSessionType(): SessionType {
  return (useExperience()?.type || SESSION_TYPES.NBY_PRODUCT_DESIGN_EXPERIENCE) as SessionType;
}

export function useExperienceType(): ExperienceType {
  const experience = useExperience();

  const types: Record<ExperienceTypeSource, ExperienceType> = {
    "CLEAN_AND_CARE_EXPERIENCE": "clean_care",
    "NBY_PRODUCT_DESIGN_EXPERIENCE": "nby"
  }

  return types?.[experience?.type || SESSION_TYPES.NBY_PRODUCT_DESIGN_EXPERIENCE] ?? "nby";
}