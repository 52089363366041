import { useTranslation } from "react-i18next";
import Typography from "@components/typography";
import Button from "@components/button";

import thumbsup from "../../../assets/icons/thumbsup.png";
import thumbsdown from "../../../assets/icons/thumbsdown.png";

export default function SessionSummaryShare({
  gridSize,
}: {
  gridSize: number;
}) {
  const { t } = useTranslation();
  return (
    <div className="" style={{ height: 6 * gridSize - 2 }}>
      <div className={"mx-3 py-4"}>
        <Typography
          size={14}
          font={"helvetica-now"}
          className={"font-medium text-nearblack"}
        >
          {t('Share With Us')}
        </Typography>
        <Typography className={"mt-2"} size={24} font={"helvetica-now"}>
          {t('Did you enjoy your Nike By You session?')}
        </Typography>

        <div className="flex font-medium">
          <Button
            size={"small"}
            theme="dark"
            disabled
            variant="outline"
            onClick={() => { }}
            className="align-center mr-4 mt-4 flex h-16 w-16 justify-center"
            track={{
              type: 'thumbs_up_button_clicked'
            }}
          >
            <img src={thumbsup} alt="" className="mt-2 h-10" />
          </Button>
          <Button
            size={"small"}
            theme="dark"
            disabled
            variant="outline"
            onClick={() => { }}
            className="align-center mt-4 flex h-16 w-16 justify-center"
            track={{
              type: 'thumbs_down_button_clicked'
            }}
          >
            <img src={thumbsdown} alt="" className="mt-2 h-10" />
          </Button>
        </div>
      </div>
    </div>
  );
}
