import { useCallback, useContext } from "react";
import { AppContext } from "../context/app.tsx";
import { useStore } from "@hooks/store.tsx";

export function useMoney() {
  const { store } = useContext(AppContext);

  const moneyFormat = useCallback((props: MoneyProps) => {
    return money({ ...props, locale: store?.locale, currency: props?.currency });
  }, [store?.locale]);

  return {
    moneyFormat,
    locale: store?.locale,
  }
}

interface MoneyProps {
  locale?: string;
  currency?: string;
  amount: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  style?: "currency" | "decimal" | "percent" | "unit";
  currencyDisplay?: "code" | "symbol" | "name";
}

function money(props: MoneyProps) {
  const { locale = 'en-US', amount, ...options } = props;

  const {
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    style = "currency",
    currency = "USD",
    currencyDisplay = "symbol",
  } = options;


  return new Intl.NumberFormat(locale, {
    style,
    currency,
    currencyDisplay,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(amount);
  // return new Intl.NumberFormat('de-DE', {
  //   style: 'currency',
  //   currency: 'EUR',
  //   maximumFractionDigits: 0,
  // }).format(amount);
}