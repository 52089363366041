import {ReactNode} from "react";
import {cn} from "@lib/shared.ts";

interface ServiceListItemProps {
  icon: ReactNode;
  children: ReactNode;
  className?: string;
  showBottomBorder?: boolean;
  containerClassName?: string;
}

export default function ListItem({icon, children, showBottomBorder, className, containerClassName}: ServiceListItemProps) {
  return (
    <div className={cn("flex relative py-5 sm:py-8 flex-grow items-center flex-1", showBottomBorder && 'border-b', containerClassName)}>
      <div className={"w-1/4 flex flex-shrink-0 justify-center items-center"}>
        {icon}
      </div>
      <div
        className={cn('mr-4 text-xl sm:text-2xl whitespace-pre font-semibold flex-1 flex justify-between', className)}>
        {children}
      </div>
    </div>
  );
}