import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import Typography from "@components/typography";
import { DEFAULT_TRANSITION } from "@lib/shared";
import { useTranslation } from "react-i18next";

export interface Step {
  id: string;
  title: string;
  completed: boolean;
}

interface ProgressHeaderProps {
  steps: Array<Step>;
  activeStep: number;
  variant?: "mobile" | "kiosk";
}

export default function ProgressHeader({
  steps,
  activeStep,
  variant = "mobile",
}: ProgressHeaderProps) {
  const [open, setOpen] = useState(true);
  const ENTRY_HEIGHT = 34;
  const height = ENTRY_HEIGHT * steps?.length + 1;
  const isMobile = variant === "mobile";

  const animated = {
    animate: {
      height: open && isMobile ? height : ENTRY_HEIGHT,
    },
  };

  function toggleOpen() {
    setOpen((i) => !i);
  }

  if (!steps?.length) {
    return null;
  }

  return (
    <div className={clsx(`top-0 z-50 bg-white p-4 pb-0`, isMobile && "sticky")}>
      {isMobile && (
        <motion.div
          onClick={toggleOpen}
          className={"absolute right-4 top-4"}
          animate={{
            rotate: open ? 0 : 180,
          }}
        >
          <Chevron />
        </motion.div>
      )}

      <motion.div
        layoutId={`progress-header`}
        {...animated}
        onClick={toggleOpen}
        className={clsx(
          "flex",
          "gap-2",
          open && isMobile ? "flex-col" : "flex-row",
          !isMobile && "items-center"
        )}
      >
        {steps?.map((step, index) => {
          const isActive = step.id === steps[activeStep].id;
          const transitioned = {
            transition: {
              duration: 0.4,
              delay: 0.05 * index,
              ease: DEFAULT_TRANSITION.ease,
            },
          };

          return (
            <div key={step.id} className={"relative flex gap-2"}>
              <motion.div
                layoutId={`step-${step.id}`}
                layout={isMobile ? undefined : "size"}
                {...transitioned}
                className={clsx([
                  `flex h-[24px] w-[24px] items-center justify-center rounded-full border-2`,
                  isActive
                    ? "border-black bg-white text-black"
                    : step.completed
                      ? "border-transparent bg-black text-white"
                      : "border-transparent bg-gray-400 text-white",
                ])}
              >
                {step.completed ? (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5 11.4999L10.2508 15.2507L17.5 8"
                      stroke="white"
                      strokeWidth="1.5"
                    />
                  </svg>
                ) : (
                  <Typography size={13}>{step.id}</Typography>
                )}
              </motion.div>

              <AnimatePresence>
                {(open || !isMobile) && (
                  <Label
                    step={step}
                    index={index}
                    activeStep={activeStep}
                    isMobile={isMobile}
                  />
                )}
              </AnimatePresence>
            </div>
          );
        })}
      </motion.div>
      {isMobile && (
        <div
          className={"border border-b border-t-0 border-neutral-200"}
          style={{ marginTop: "2px" }}
        />
      )}
    </div>
  );
}

function Chevron() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.0332 15.5268L11.9992 8.56079L18.9662 15.5268"
        stroke="#111111"
        strokeWidth="1.5"
      />
    </svg>
  );
}

function Label({
  step,
  index,
  activeStep,
  isMobile,
}: {
  step: Step;
  index: number;
  activeStep: number;
  isMobile: boolean;
}) {
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      const width = contentRef?.current?.getBoundingClientRect()?.width;
      if (!width) {
        return;
      }
      setWidth(width + 10);
    }, 800);
  }, []);

  return (
    <motion.div
      className={clsx(
        `flex items-center overflow-x-hidden whitespace-nowrap text-black`,
        isMobile ? "absolute left-9 top-[1px]" : "relative"
      )}
      initial={{
        x: isMobile ? -5 : 0,
        opacity: 0,
        width: isMobile ? "auto" : 0,
      }}
      animate={{
        x: 0,
        opacity: activeStep === index ? 1 : 0.5,
        width: isMobile ? "auto" : activeStep === index ? width : 0,
      }}
      exit={{
        x: 0,
        opacity: 0,
        transition: { duration: 0, delay: 0 },
        width: isMobile ? "auto" : 0,
      }}
      transition={{
        duration: 0.2,
        delay: isMobile ? 0.4 + index * 0.1 : 1,
        ease: DEFAULT_TRANSITION.ease,
      }}
    >
      <div ref={contentRef}>
        <Typography size={14} className={"font-medium"}>
          {t(step.title)}
        </Typography>
      </div>
    </motion.div>
  );
}
