import Typography from "@components/typography";

export default function GridBoxStatusSection({
  title,
  gridSize,
}: {
  title: string;
  gridSize: number;
}) {
  return (
    <>
      <div
        className={"flex items-center bg-white"}
        style={{
          height: gridSize * 3 - 2,
          paddingLeft: gridSize * 0.7,
        }}
      >
        <Typography className={"font-medium text-nearblack"} size={24}>
          {title}
        </Typography>
      </div>
      <div style={{ height: 2 }}></div>
    </>
  );
}
