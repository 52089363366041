import { useTranslation } from "react-i18next";
import Typography from "@components/typography";
// import Button from "@components/button";

export default function SessionSummarySupport({
  gridSize,
  showSatisfied = false,
}: {
  gridSize: number;
  showSatisfied?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={showSatisfied ? "flex border-opacity-10" : ""}
        style={{ height: 6 * gridSize - 2 }}
      >
        <div className={"mx-3 py-4"}>
          <Typography
            size={24}
            font={"helvetica-now"}
            className={"font-medium text-nearblack"}
          >
            {t('Need Support?')}
          </Typography>
          <Typography
            className={"mt-4"}
            lineHeight={"24px"}
            size={16}
            font={"helvetica-now"}
          >
            {t('Contact us at nike.com or in your Nike App for any needs regarding your order.')}
          </Typography>

          {/* <div className="font-medium">
            <Button
              size={"small"}
              theme="dark"
              disabled
              variant="outline"
              onClick={() => { }}
              className={"mt-4 py-1"}
            >
              Chat Support
            </Button>
          </div> */}
        </div>
      </div>
      {/* {showSatisfied && (
        <div
          style={{
            height: 4 * gridSize,
          }}
          className={"border-t-2 border-opacity-10"}
        >
          <div className={"mx-3 py-4"}>
            <Typography
              size={24}
              font={"helvetica-now"}
              className={"font-medium text-nearblack"}
            >
              Not Satisfied?
            </Typography>
            <div className="font-medium">
              <Button
                size={"small"}
                theme="dark"
                disabled
                variant="outline"
                onClick={() => {}}
                className={"mt-4 py-1"}
              >
                Initiate Return
              </Button>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}
