import { gql } from "@apollo/client";

export const PUBLIC_GET_CUSTOMER_TOKENS = gql`
query Query($nikeAccessToken: String) {
  getCustomerTokens(nike_access_token: $nikeAccessToken) {
    access {
      expires
      token
    }
    refresh {
      expires
      token
    }
  }
}
`;

export const CREATE_APPLE_PASS = gql`
mutation CreateApplePass($object: CreateWalletPassInput!) {
  createApplePass(object: $object) {
    passUrl
  }
}
`;

export const CREATE_GOOGLE_PASS = gql`
mutation CreateGooglePass($object: CreateWalletPassInput!) {
  createGooglePass(object: $object) {
    passUrl
  }
}
`;

export const GET_USER = gql`
fragment SessionFragment on Session {
  type
  created_date
  updated_date
  latest_status
  wait_time_at_session_upsert
  text_notifications_allowed
  has_contact_number
  session_statuses {
    id
    type
    timestamp
  }
  booking {
    type
    start_date
    end_date
  }
  locker {
    id
  }
  store_id
  experience_id
  store {
    id
    timezone
    locale
  }
  designs {
    lastEvaluatedKey
    edges {
      node {
        id
        product {
          id
          name
        }
        product_size {
          id
          name
        }
        inspirations {
          id
          image_url
        }
        product_color {
          id
          name
          hex
          image_url
        }
      }
    }
  }
}
query GetUser($sessionType: SessionType!) {
  whoAmI(session_type: $sessionType) {
    nu_id
    g_id
    first_name
    last_name
    g_preferred_name
    queued_sessions {
      id
      ...SessionFragment
    }
    past_sessions {
      id
      ...SessionFragment
    }
    future_sessions {
      id
      ...SessionFragment
    }
  }
}
`;

export const GET_USER_TOKENS = gql`
  query GetUserTokens($sessionType: SessionType!) {
    whoAmI(session_type: $sessionType) {
      nu_id
      tokens {
        access {
          expires
          token
        }
        refresh {
          expires
          token
        }
      }
    }
  }
`;