import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@components/typography";
import Button from "@components/button";
import { useNavigate } from "react-router-dom";
import { Design, ExperienceProduct, SESSION_STATUS_TYPES, Session, Store } from "../../../data/types";
import { usePublicQuery } from "@hooks/apollo";
import { STORE } from "../../../data/gql/queries/store";
import { AppContext } from "../../../context/app";
import { useDeleteDesigns } from "@hooks/session";
import { useUser } from "@hooks/user";
import { getDesigns } from "@lib/session";

export default function SessionSummaryDesign({
  session,
  gridSize,
  showDetails = true,
  status,
  store
}: {
  status?: string;
  showDetails?: boolean;
  session: Session;
  gridSize: number;
  store: Store;
}) {
  const { user, loading } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const designs = getDesigns(session);
  const { storeId, experienceId } = useContext(AppContext);
  const { handleDelete, deleting } = useDeleteDesigns();
  const { data } = usePublicQuery(STORE, {
    variables: {
      objectId: storeId
    }
  });
  const experience = data?.store?.experiences?.find((experience: { id: string }) => {
    return experience.id === experienceId
  });

  const showEdit = (status &&
    ![
      SESSION_STATUS_TYPES.IN_PROGRESS,
      SESSION_STATUS_TYPES.COMPLETED,
    ].includes(status)) ||
    !status;

  const height = useMemo(() => {
    if (
      status &&
      [
        SESSION_STATUS_TYPES.IN_PROGRESS,
        SESSION_STATUS_TYPES.COMPLETED,
      ].includes(status)
    ) {
      return 10 * gridSize;
    }

    if (designs.length === 0) return 6 * gridSize;

    return (2 + (4 * designs.length) + (designs.length < 3 ? 2 : 0)) * gridSize;
  }, [status, gridSize]);

  return (
    <div style={{ height: height - 2 }}>
      <div className={`h-full w-full ${showDetails ? "border-t-1 flex border-opacity-10" : ""}`}>
        <div className={"w-full ml-3 mr-5 pt-4 flex flex-col"}>
          <Typography
            size={24}
            font={"helvetica-now"}
            className={"font-medium text-nearblack"}
          >
            {status &&
              ["in_progress", "processing", "pickup", "completed"].includes(
                status
              )
              ? t("Your Order")
              : t("Your Design Session")}
          </Typography>

          {designs.map((design) => {
            const product = (experience?.products || [])
              .find((p: ExperienceProduct) => p.id === design?.product?.id) as ExperienceProduct;

            return (
              <div className="mt-5 flex" key={design.id}>
                <img
                  className="mr-3 w-28 rounded bg-neutral-100 px-1 pb-4"
                  src={
                    design?.product_color?.image_url || product?.image_url
                  }
                  alt={product?.name}
                />

                <div className="flex flex-col justify-between font-medium flex-grow">
                  <Typography
                    size={16}
                    font={"helvetica-now"}
                    className={"font-medium"}
                  >
                    {design.product &&
                      `Nike ${design.product?.name} By You`}
                  </Typography>
                  <div className="flex flex-wrap gap-2 justify-between">
                    {showEdit && <Button
                      size={"small"}
                      theme="light"
                      variant="text"
                      onClick={() => {
                        navigate(`/pre-design/${design.id}`);
                      }}
                      track={{
                        type: 'edit_design_button_clicked'
                      }}
                      className={`border-2 border-neutral-300 bg-transparent py-1 disabled:border-opacity-50 disabled:bg-transparent disabled:text-opacity-50 w-fit`}
                    >
                      {t("Edit")}
                    </Button>}
                    {showEdit && !loading && <Button
                      size={"xsmall"}
                      theme="light"
                      variant="text"
                      onClick={async () => handleDelete(user, [design.id])}
                      disabled={deleting}
                      track={{
                        type: 'edit_design_button_clicked'
                      }}
                      className={`opacity-30 bg-transparent px-0 w-fit underline`}
                    >
                      {t("Remove")}
                    </Button>}
                  </div>
                </div>
              </div>
            )
          })}
          <div className="flex-grow" />
          {designs.length > 0 && designs.length < 3 && showEdit && (
            <div className="bg-black w-full opacity-20" style={{ height: '1px' }} />
          )}
          {designs.length > 0 && designs.length < 3 && showEdit && (
            <div style={{ height: 2 * gridSize - 2 }} className="flex items-center">
              <Button
                size={"small"}
                theme="light"
                variant="text"
                onClick={() => {
                  navigate(`/pre-design`);
                }}
                track={{
                  type: 'add_service_button_clicked'
                }}
                className={`border-2 border-neutral-300 bg-transparent py-1 disabled:border-opacity-50 disabled:bg-transparent disabled:text-opacity-50 w-fit`}
              >
                {t("Add Service")}
              </Button>
            </div>
          )}
        </div>
      </div>
      {showDetails && <Details status={status} store={store} />}
    </div>
  );
}

function Details({ status, store }: { status?: string, store: Store }) {
  const { t } = useTranslation();
  if (
    status &&
    ["in_progress", "processing", "pickup", "completed"].includes(status)
  ) {
    const title = {
      in_progress: t("Production In Progress"),
      processing: t("Production In Progress"),
      pickup: t("Production Complete"),
      completed: t("Order Picked Up"),
    }?.[status];

    return (
      <div className={"border-t-2 border-opacity-10 px-3 pb-3"}>
        <div className={"my-5 flex"}>
          <Clock />
          <div className={"ml-3"}>
            <Typography size={16} className={"font-medium"}>
              {title}
            </Typography>
          </div>
        </div>
        <div className={"flex"}>
          <Map />
          <div className={"ml-3"}>
            <Typography size={16} className={"font-medium"}>
              {store.name}
            </Typography>
            <Typography
              size={14}
              style={{
                whiteSpace: 'pre-wrap'
              }}
            >
              {store.address}
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
}

function Map() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_1325_4964)">
        <path
          fill="#111"
          d="M12 21.747l-.468.586a.75.75 0 00.936 0L12 21.747zM18 9c0 .92-.303 2.081-.824 3.362-.515 1.269-1.222 2.6-1.974 3.843a38.411 38.411 0 01-2.217 3.274c-.695.914-1.223 1.498-1.453 1.682l.936 1.172c.395-.314 1.023-1.042 1.711-1.947a39.904 39.904 0 002.306-3.404c.78-1.288 1.526-2.691 2.08-4.055.55-1.352.935-2.723.935-3.927H18zm-5.532 12.16c-.23-.183-.758-.767-1.453-1.681a38.41 38.41 0 01-2.217-3.274c-.752-1.243-1.458-2.574-1.974-3.843C6.303 11.081 6 9.921 6 9H4.5c0 1.204.385 2.575.934 3.927.555 1.364 1.302 2.767 2.08 4.055.78 1.288 1.6 2.474 2.307 3.404.688.905 1.316 1.633 1.711 1.947l.936-1.172zM6 9a6 6 0 016-6V1.5A7.5 7.5 0 004.5 9H6zm6-6a6 6 0 016 6h1.5A7.5 7.5 0 0012 1.5V3z"
        ></path>
        <path
          stroke="#111"
          strokeWidth="1.5"
          d="M15.25 9a3.25 3.25 0 11-6.5 0 3.25 3.25 0 016.5 0z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1325_4964">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function Clock() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path stroke="#111" strokeWidth="1.5" d="M12 6v6h5.25"></path>
      <path
        stroke="#111"
        strokeWidth="1.5"
        d="M20.25 12a8.25 8.25 0 11-16.5 0 8.25 8.25 0 1116.5 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
