import { useContext, useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

import Typography from "@components/typography";
import {
  checkIfToday,
  checkIfTodayDateString,
  dateString,
  getDateTimeForDateString,
  getDatetimeForTime,
  simpleDateFormat,
} from "@lib/datetime";
import { useHoldBooking, useReserveBooking } from "@hooks/session";
import requestToast from "@components/request-toast";
import Status from "@components/status";
import BottomButton from "@components/bottom-button";
import bookingSteps from "@lib/shared";
import { usePublicQuery } from "@hooks/apollo";
import ChevronIcon from "@components/icon-chevron";
import { useUser } from "@hooks/user";
import Loading from "@components/loading";

import BookingTimeSelector from "./booking-time-selector";
import { TimeSlot } from "../../data/types";
import { AVAILABLE_DAY_LIST, STORE } from "../../data/gql/queries/store";
import { AppContext } from "../../context/app";
import BookingCalendar from "./booking-calendar/booking-calendar";
import { useSearchParams } from "react-router-dom";
import { useExperienceType, useSessionType } from "@hooks/experience.tsx";

export default function Book() {
  const experienceType = useExperienceType();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot | null>(null);
  const { user, loading } = useUser();
  const { storeId, experienceId, language } = useContext(AppContext);
  const [calendarOpen, setCalendarOpen] = useState(true);
  const [calendarStart, setCalendarStart] = useState(dateString());
  const sessionType = useSessionType();
  const { data, loading: fetching } = usePublicQuery(STORE, {
    variables: {
      objectId: storeId
    }
  });
  const {
    data: availableDaysData,
    refetch
  } = usePublicQuery(AVAILABLE_DAY_LIST, {
    variables: {
      storeId,
      experienceId,
      sessionType,
      startDatestring: calendarStart,
      endDatestring: dateString(DateTime.fromISO(calendarStart).endOf("month").plus({ weeks: 1 }).toJSDate()),
    }
  });
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const timezone = data?.store?.timezone;
  const bookingWindowDate = searchParams.get("bwd") || "";
  const bookingWindowStartHour = searchParams.get("bwsh") || "";
  const bookingWindowEndHour = searchParams.get("bweh") || "";
  const bookingWindowStartMinute =
    searchParams.get("bwsm") === "0" || searchParams.get("bwsm") === "30"
      ? searchParams.get("bwsm")
      : "0";
  const bookingWindowEndMinute =
    searchParams.get("bwem") === "0" || searchParams.get("bwem") === "30"
      ? searchParams.get("bwem")
      : "0";
  const [date, setDate] = useState<string>(dateString());
  const [bookingWindowEndMs, setBookingWindowEndMs] = useState<number>();
  const [bookingWindowStartMs, setBookingWindowStartMs] = useState<number>();

  const bookingWindowMode =
    !!(bookingWindowDate &&
      bookingWindowStartHour &&
      bookingWindowEndHour &&
      bookingWindowStartMinute &&
      bookingWindowEndMinute);

  useEffect(() => {
    let bookingWindowStartMs = 0,
      bookingWindowEndMs = 0;
    if (bookingWindowMode) {
      const [year, month, day] = bookingWindowDate.split("-");

      bookingWindowStartMs = getDatetimeForTime(
        {
          year: Number(year),
          month: Number(month),
          day: Number(day),
          hour: Number(bookingWindowStartHour),
          minute: Number(bookingWindowStartMinute),
          second: 0,
          millisecond: 0,
        },
        timezone
      );
      setDate(bookingWindowDate);
      setBookingWindowStartMs(bookingWindowStartMs);
      bookingWindowEndMs = getDatetimeForTime(
        {
          year: Number(year),
          month: Number(month),
          day: Number(day),
          hour: Number(bookingWindowEndHour),
          minute: Number(bookingWindowEndMinute),
          second: 0,
          millisecond: 0,
        },
        timezone
      );
      setBookingWindowEndMs(bookingWindowEndMs);
    }
  }, [bookingWindowMode]);

  const currentDate = useMemo(() => {
    const isToday = checkIfTodayDateString(date);

    return `${isToday ? t("Today") + " — " : ""}${simpleDateFormat(
      getDateTimeForDateString(date),
      timezone,
      language,
      true
    )}`;
  }, [timezone, date]);

  // const navigateTo = useMemo(() => {
  //   return '
  // }, [experienceType]);

  const holdBooking = useHoldBooking({
    // experienceType
    navigateTo: experienceType === 'clean_care' ? '/cleaning-pricing' : '/sign-in',
  });
  const [reserveBooking, { loading: booking }] = useReserveBooking();

  const steps = bookingSteps(0);

  const onClickBook = async () => {
    if (booking) return;
    if (!selectedTimeSlot?.start_date) {
      requestToast(t(`Please select a session.`), "ERROR");
      return;
    }

    if (user?.nu_id) {
      await reserveBooking({ user, timeslot: selectedTimeSlot });
    } else {
      await holdBooking(selectedTimeSlot, getDateTimeForDateString(date, timezone));
    }
  };

  const handleDateChange = (date: string) => {
    setDate(date);
    setCalendarOpen(false);
  }

  const handleOnActiveStartDateChange = ({ activeStartDate }: { activeStartDate: Date }) => {
    setCalendarStart(dateString(activeStartDate));
  }

  if (booking || loading || fetching) return <Loading />;

  return (
    <Status
      activeStep={steps.activeIndex}
      steps={steps.steps}
      outside={calendarOpen ? null : <BottomButton
        onClick={onClickBook}
        theme={"light"}
        disabled={!selectedTimeSlot}
        fullWidth
        track={{
          type: 'book_continue_button_clicked'
        }}
      >
        {t('Continue')}
      </BottomButton>}
    >

      <motion.div className={"px-4 pb-32 pt-6"}>
        <div className={"mb-6"}>
          <Typography className={"font-medium text-nearblack"} size={24}>
            {data?.store?.name}
          </Typography>

          <div className="flex gap-2 items-center">
            <Typography className={"text-neutral-500"} size={24}>
              {currentDate}
            </Typography>
            {!bookingWindowMode && <motion.div
              onClick={() => {
                if (!calendarOpen) refetch();
                setCalendarOpen(!calendarOpen);
              }}
              animate={{
                rotate: calendarOpen ? 0 : 180,
              }}
            >
              <ChevronIcon color="#8B8B8B" />
            </motion.div>}
          </div>
          <Typography className='text-nearblack' size={12}>
            {t('You’ll be Notified and Reminded Via Email')}
          </Typography>
        </div>
        <AnimatePresence>
          {calendarOpen ? (
            <BookingCalendar
              value={date}
              onChange={handleDateChange}
              locale={language}
              onActiveStartDateChange={handleOnActiveStartDateChange}
              availableDays={availableDaysData?.availableDayList?.objects}
            />
          ) : (
            <BookingTimeSelector
              buttonsDisabled={loading}
              onSelect={setSelectedTimeSlot}
              selectedTimeSlot={selectedTimeSlot}
              selectedDate={date}
              bookingWindowStartMs={bookingWindowStartMs}
              bookingWindowEndMs={bookingWindowEndMs}
              timezone={data?.store?.timezone}
              locale={language}
            />
          )}
        </AnimatePresence>
      </motion.div>
    </Status>
  );
}
