import { useEffect } from "react";
import Loading from "@components/loading.js";

import { useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/user.js";

export default function Start() {
  const { user, loading } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (user && user?.next_session) {
      navigate("/session-manager");
    } else {
      navigate("/intro");
    }
  }, [user, navigate, loading]);

  return <Loading />;
}
