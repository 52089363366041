import { ReactNode } from "react";
import PageTransition from "./page-transition";
import ProgressHeader, { Step } from "./progress-header";
import { motion } from "framer-motion";
import BackgroundGrid from "./background-grid";
import { GRID_FIT } from "../constants";
import {cn} from "@lib/shared";

export interface SessionManagerProps {
  children: ReactNode;
  steps?: Array<Step>;
  activeStep?: number;
  outside?: ReactNode;
  defaultKey?: string;
  showGrid?: boolean;
  transparent?: boolean;
  title?: string;
  subtitle?: string;
  containerClassName?: string;
}

export default function Status({
  children,
  activeStep,
  steps,
  outside,
  showGrid = false,
  defaultKey = "default",
  transparent = false,
  containerClassName,
}: SessionManagerProps) {
  return (
    <PageTransition
      defaultKey={defaultKey}
      className={`max-w-screen z-0 min-h-full w-full bg-contain bg-no-repeat ${transparent ? "bg-transparent" : "bg-white"
        }`}
      type={{
        in: "right",
        out: "fade",
      }}
    >
      {showGrid && <BackgroundGrid fit={GRID_FIT} asPortal={false} />}
      {steps && activeStep !== undefined && (
        <ProgressHeader activeStep={activeStep} steps={steps} />
      )}
      <motion.div className={cn("z-20 min-h-full w-full", containerClassName)}>{children}</motion.div>

      {outside && outside}
    </PageTransition>
  );
}
