import { Navigate } from "react-router-dom";
import SessionSummary from "./session-summary/session-summary";
import Status from "@components/status";
import { useUser } from "@hooks/user";
import { STORE } from "../../data/gql/queries/store";
import { usePublicQuery } from "@hooks/apollo";
import { useContext, useMemo } from "react";
import { AppContext } from "../../context/app";
import { SESSION_STATUS_TYPES } from "../../data/types";

export default function SessionManager() {
  const { user, loading } = useUser();
  const { storeId } = useContext(AppContext);
  const { data } = usePublicQuery(STORE, {
    variables: {
      objectId: storeId
    }
  });

  const session = (user?.next_session || user?.last_session);
  // for now, don't show session manager for completed sessions
  const afterSession = useMemo(() => {
    return session?.latest_status
      ? [
        SESSION_STATUS_TYPES.NO_SHOW,
        SESSION_STATUS_TYPES.PROCESSING,
        SESSION_STATUS_TYPES.PICKUP,
        SESSION_STATUS_TYPES.COMPLETED,
      ].includes(session?.latest_status)
      : false;
  }, [session?.latest_status]);

  if (!loading && (!session || afterSession)) {
    return <Navigate to={"/intro"} />;
  }

  return (
    <Status transparent steps={[]} activeStep={0} showGrid={true}>
      <div className="relative max-w-full">
        {session && <SessionSummary
          session={session}
          store={data?.store || {}}
        />}
      </div>
    </Status>
  );
}
