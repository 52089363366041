import { ReactNode } from "react";
import Typography from "@components/typography";

interface CardProps {
  title: string;
  subtitle: string | ReactNode;
  image?: string;
  onClick?: () => void;
  className?: string;
  alt?: string;
  active?: boolean;
}

export default function Card({
  title,
  subtitle,
  alt,
  image,
  onClick,
  className,
}: CardProps) {
  return (
    <div
      className={`${className} flex flex-col`}
      onClick={onClick}
    >
      <div className={"mt-15 h-full w-full"}>
        <img src={image} />
      </div>

      <div className={"m-4 font-medium text-black"}>
        <Typography size={16} lineHeight={"16px"}>
          {title}
        </Typography>
        <Typography size={16}>{subtitle}</Typography>

        {alt && (
          <Typography
            size={16}
            lineHeight={"16px"}
            className={"absolute bottom-4 right-4"}
          >
            {alt}
          </Typography>
        )}
      </div>
    </div>
  );
}
