import { motion } from "framer-motion";
import React, { CSSProperties, useMemo } from "react";

export type TransitionType = "instant" | "fade" | "left" | "right" | "vapor";

export interface SpecificTransitionType {
  in: TransitionType;
  out: TransitionType | "stay";
}

const transitions: Record<TransitionType, any> = {
  fade: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  },
  instant: {
    initial: false,
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 1,
    },
  },

  left: {
    initial: {
      x: "-100%",
    },
    animate: {
      x: "0%",
    },
    exit: {
      x: "-100%",
    },
  },

  right: {
    initial: {
      x: "100%",
    },
    animate: {
      x: "0%",
    },
    exit: {
      x: "100%",
    },
  },

  vapor: {
    initial: {
      scale: 1.5,
      opacity: 0,
    },
    animate: {
      scale: 1,
      opacity: 1,
    },
    exit: {
      scale: 0.5,
      opacity: 0,
    },
  },
};

interface PageTransitionProps {
  type?: TransitionType | SpecificTransitionType;
  children?: React.ReactNode;
  style?: CSSProperties;
  className?: string;
  defaultKey?: string;
}

export default function PageTransition({
  type,
  children,
  className,
  defaultKey = "",
  ...other
}: PageTransitionProps) {
  const { initial, animate, exit } = useMemo(() => {
    if (typeof type === "string") {
      return transitions[type];
    }

    if (!type?.in || !type?.out) {
      return transitions.fade;
    }

    const exit =
      type.out === "stay"
        ? {
          opacity: [1, 1, 0],
          transition: {
            duration: 2,
          },
        }
        : transitions[type.out].exit ?? {};

    return {
      initial: transitions[type.in].initial ?? {},
      // add all the animation type's animate
      // to make it possible to pick any in / out
      animate: transitions[type.in]?.animate ?? {},
      exit,
    };
  }, []);

  const classes = useMemo(() => {
    const bg = className?.includes("bg-") ? "" : "bg-white";

    return `${bg} w-full max-w-screen left-0 top-0 ${className}`;
  }, [className]);

  return (
    <motion.div
      initial={{
        ...initial,
        position: "absolute",
      }}
      animate={{
        ...animate,
        position: "relative",
      }}
      exit={{
        ...exit,
        position: "absolute",
      }}
      style={{
        height: "100%",
      }}
      className={classes}
      key={defaultKey}
      {...other}
    >
      {children}
    </motion.div>
  );
}
