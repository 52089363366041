import { useLazyQuery, useMutation } from "@apollo/client";
import requestToast from "@components/request-toast";
import { confirmAlert } from "react-confirm-alert";
import { BOOKING_TYPES, SESSION_STATUS_TYPES, Session, TimeSlot, User, Guest } from "../data/types";
import { usePublicLazyQuery, usePublicMutation } from "./apollo";
import { useContext, useState } from "react";
import {
  CREATE_GUEST_SESSIONS,
  CREATE_SESSIONS,
  DELETE_HELD_SESSION,
  DELETE_SESSIONS,
  HOLD_SESSION,
  OPT_IN_TEXT_NOTIFICATIONS,
  OPT_OUT_TEXT_NOTIFICATIONS,
  UPDATE_SESSION
} from "../data/gql/mutations/session";
import { AVAILABLE_SESSION_LIST } from "../data/gql/queries/session";
import { AppContext } from "../context/app";
import { GET_USER } from "../data/gql/queries/user";
import { simpleTimeAndDateFormat } from "@lib/datetime";
import { useNavigate } from "react-router-dom";
import { throwFirstError } from "@lib/shared";
import { privateClient, publicClient } from "../data/providers/apollo";
import { useTranslation } from "react-i18next";
import { VITE_STAGE } from "../constants";
import { DELETE_DESIGNS } from "../data/gql/mutations/design";
import { useSessionType } from "@hooks/experience.tsx";
import { t } from "i18next";

interface HandleBookingProps {
  user?: User | null;
  timeslot: TimeSlot;
  guest?: Guest;
}

type ReserveBookingErrorType = 'already_has_session';

export const useReserveBooking = (): [(props: HandleBookingProps) => Promise<void>, {
  loading: boolean,
  error: any,
  errorType: ReserveBookingErrorType | null
}] => {
  const {
    storeId: store_id,
    experienceId: experience_id,
    heldTimeSlot,
    setHeldTimeSlot,
    language
  } = useContext(AppContext);
  const [errorType, setErrorType] = useState<ReserveBookingErrorType | null>(null);
  const sessionType = useSessionType();
  const [createSessions, { error: createError }] = useMutation(CREATE_SESSIONS, {
    refetchQueries: [
      {
        query: GET_USER,
        variables: { sessionType: sessionType },
      },
    ],
  });
  const [createGuestSession, { error: createGuestError }] = useMutation(CREATE_GUEST_SESSIONS, {
    client: publicClient,
  });
  const [getUser] = useLazyQuery(GET_USER, {
    variables: {
      sessionType
    },
    fetchPolicy: "network-only",
  });
  const [updateSession, { error: updateError }] = useMutation(UPDATE_SESSION, {
    refetchQueries: [
      {
        query: GET_USER,
        variables: { sessionType: sessionType },
      },
    ],
  });
  const [
    deleteHeldTimeSlot,
    { loading: deletingHeld, error: deleteHeldError }
  ] = usePublicMutation(DELETE_HELD_SESSION);
  const [booking, setBooking] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const removeHeldTimeSlot = async () => {
    if (deletingHeld || deleteHeldError) return;
    if (heldTimeSlot?.id) {
      await deleteHeldTimeSlot({
        variables: {
          objectId: heldTimeSlot.id,
        },
      })
        .then(throwFirstError)
        .catch((error) => {
          if (VITE_STAGE !== 'production' && error instanceof Error) {
            console.error(error.message);
          }
        });
      setHeldTimeSlot();
    }
  }

  const completeBooking = async ({ timeslot, id, guest }: { timeslot: TimeSlot, id?: string, guest?: Guest }) => {
    try {
      setBooking(true);
      const now = Date.now();
      const session = {
        type: sessionType,
        booking: {
          type: BOOKING_TYPES.RESERVED,
          start_date:
            typeof timeslot?.start_date === "string"
              ? parseInt(timeslot.start_date)
              : timeslot.start_date,
          end_date:
            typeof timeslot?.end_date === "string"
              ? parseInt(timeslot.end_date)
              : timeslot.end_date,
        },
        session_statuses: [
          {
            type: SESSION_STATUS_TYPES.RESERVED,
            timestamp: now,
          },
        ],
      }

      if (id) {
        const variables = {
          objectId: id,
          object: session
        };
        await updateSession({ variables }).then(throwFirstError);
        navigate("/confirmed");
      } else {
        if (guest) {
          await createGuestSession({
            variables: {
              object: {
                booking: session.booking,
                store_id,
                experience_id,
                preferred_name: guest.preferred_name,
                type: sessionType,
              },
              g_email: guest.g_email,
            },
          }).then(async (res) => {
            throwFirstError(res);
            localStorage.setItem("tokens", JSON.stringify({ access: res.data.addGuestSession.access_token }));
            await getUser();
            navigate("/confirmed");
          }).catch((error) => {
            if (error.message === 'User already has a session for this store/experience') {
              setErrorType('already_has_session')
            }
          });
        } else {
          await createSessions({
            variables: {
              objects: [
                {
                  ...session,
                  store_id,
                  experience_id,
                },
              ],
            },
          }).then(throwFirstError);

          navigate("/confirmed");
        }

      }
      await removeHeldTimeSlot();
      setBooking(false);
    } catch (error) {
      setBooking(false);
      if (VITE_STAGE !== 'production' && error instanceof Error) {
        console.error(error.message);
      }
      requestToast(t("An error occurred. Please try again later."), "ERROR");
    }
  };

  const handleBooking = async ({ user, timeslot, guest }: HandleBookingProps) => {
    const noGuestOrUser = !guest && !user;
    if (noGuestOrUser || booking || createError || createGuestError || updateError) return;
    const nextSession = user?.next_session;
    if (nextSession) {
      const options = {
        title: t("Rebook Session?"),
        message: t("You have an existing session at {{time}}. Would you like to cancel and rebook?", {
          time: simpleTimeAndDateFormat(
            nextSession?.booking?.start_date || 0,
            nextSession?.store?.timezone,
            language,
          )
        }),
        buttons: [
          {
            label: t("Rebook"),
            onClick: async () => {
              await completeBooking({ timeslot, id: nextSession.id, guest });
            },
          },
          {
            label: t("Keep Existing"),
            onClick: async () => {
              await removeHeldTimeSlot();
              navigate("/session-manager");
            },
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      };

      confirmAlert(options);
    } else {
      await completeBooking({ timeslot, guest });
    }
  };

  return [handleBooking, { loading: booking, error: createError || updateError, errorType }];
}

export const useHoldBooking = ({ navigateTo = `/sign-in` }: { navigateTo: string }) => {
  const {
    setHeldTimeSlot,
    storeId: store_id,
    experienceId: experience_id
  } = useContext(AppContext);
  const sessionType = useSessionType();
  const [holdSession, { loading, error }] = usePublicMutation(HOLD_SESSION);
  const navigate = useNavigate();
  const [getAvailableSessions] = usePublicLazyQuery(AVAILABLE_SESSION_LIST);
  const { t } = useTranslation();

  const holdBooking = async (timeSlot: TimeSlot, selectedDate: number) => {
    if (loading || error) return;

    try {
      const { data } = await getAvailableSessions({
        variables: {
          session_type: sessionType,
          store_id,
          experience_id,
          date: selectedDate
        },
        fetchPolicy: "network-only",
      });

      if (data?.availableSessionList && !(data?.availableSessionList?.objects || []).find(
        (slot: TimeSlot) => slot.start_date === timeSlot.start_date
      )) {
        requestToast(t("Another user has already held this slot."), "ERROR");
        return;
      }

      const session = {
        type: sessionType,
        booking: {
          type: BOOKING_TYPES.HELD,
          start_date:
            typeof timeSlot?.start_date === "string"
              ? parseInt(timeSlot.start_date)
              : timeSlot.start_date,
          end_date:
            typeof timeSlot?.end_date === "string"
              ? parseInt(timeSlot.end_date)
              : timeSlot.end_date,
        },
        store_id,
        experience_id,
      }

      const heldSessionData = await
        holdSession({
          variables: {
            object: session
          },
        })
          .then(throwFirstError);

      setHeldTimeSlot({
        ...timeSlot,
        id: heldSessionData?.data.holdSession?.id,
        // ten minutes from now
        expiration_date: Date.now() + 600_000
      });

      navigate(navigateTo);
    } catch (error) {
      if (VITE_STAGE !== 'production' && error instanceof Error) {
        console.error(error.message);
      }
      requestToast(t("An error occurred. Please try again later."), "ERROR");
    }
  };

  return holdBooking;
};

export function useDeleteSessions() {
  const [deleted, setDeleted] = useState(false);
  const [deleteSessionsById, { loading, error }] = useMutation(DELETE_SESSIONS);
  const [updateSession] = useMutation(UPDATE_SESSION);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleDelete = (user: User | null) => {
    if (!user) return;
    const options = {
      title: t("Cancel Session?"),
      message: t("Are you sure you want to cancel your session?"),
      buttons: [
        {
          label: t("Cancel Session"),
          onClick: async () => {
            
            await completeDelete(user);
            navigate("/intro");
          }
        },
        {
          label: t("Keep Session"),
          onClick: () => {
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    };

    confirmAlert(options);
  };

  const handleUpdateSession = async (session: Session) => {
    return updateSession({
      variables: {
        objectId: session.id,
        object: {
          session_statuses: [
            {
              type: SESSION_STATUS_TYPES.USER_CANCELLED,
              timestamp: Date.now(),
            },
          ],
        },
      },
    })
      .then(throwFirstError);
  };

  const completeDelete = async (user: User | null, retried = false) => {
    if (!user?.next_session && !user?.last_session) return;
    try {
      if (user?.next_session) {
        if (loading || error) return;
        const session = user?.next_session || user?.last_session;
        await handleUpdateSession(session);
        await deleteSessionsById({ variables: { objectIds: [session?.id] } })
          .then(throwFirstError);
        privateClient.clearStore();
      }

      requestToast(
        t("Successfully deleted sessions and designs"),
        "SUCCESS"
      );
      setDeleted(true);
    } catch (error: any) {
      if (VITE_STAGE !== 'production' && error instanceof Error) {
        console.error(error.message);
      }

      if(error?.networkError?.statusCode === 401 && !retried){
        await completeDelete(user, true)
      } else {
        requestToast(t("An error occurred. Please try again later."), "ERROR");
      }
    }
  }

  return {
    deleted,
    deleting: loading,
    handleDelete,
  };
}

export function useDeleteDesigns() {
  const [deleted, setDeleted] = useState(false);
  const sessionType = useSessionType();
  const [deleteDesigns, { loading, error }] = useMutation(DELETE_DESIGNS, {
    refetchQueries: [
      {
        query: GET_USER,
        variables: { sessionType },
      },
    ],
  });
  const { t } = useTranslation();

  const handleDelete = (user: User | null, ids: Array<string>) => {
    if (!user?.next_session && !user?.last_session) return;
    const options = {
      title: t("Delete Design?"),
      message: t("Are you sure you want to delete your design?"),
      buttons: [
        {
          label: t("Delete Design"),
          onClick: async () => {
            await completeDelete(ids);
            // window.location.reload();
          }
        },
        {
          label: t("Keep Design"),
          onClick: () => {
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    };

    confirmAlert(options);
  };

  const completeDelete = async (ids: Array<string>) => {
    try {
      if (loading) return;
      await deleteDesigns({
        variables: {
          objectIds: ids
        }
      })
        .then(throwFirstError);

      requestToast(
        t("Successfully deleted design"),
        "SUCCESS"
      );
      setDeleted(true);
    } catch (error: any) {
      if (VITE_STAGE !== 'production' && error instanceof Error) {
        console.error(error.message);
      }
      requestToast(t("An error occurred. Please try again later."), "ERROR");
    }
  }

  return {
    deleted,
    deleting: loading,
    error,
    handleDelete,
  };
}

export const useTextOptIn = () => {
  const sessionType = useSessionType();
  const [
    optInTextNotifications,
    { loading: optInLoading }
  ] = useMutation(OPT_IN_TEXT_NOTIFICATIONS, {
    refetchQueries: [
      {
        query: GET_USER,
        variables: { sessionType },
      },
    ],
  });
  const [
    optOutTextNotifications,
    { loading: optOutLoading }
  ] = useMutation(OPT_OUT_TEXT_NOTIFICATIONS, {
    refetchQueries: [
      {
        query: GET_USER,
        variables: { sessionType },
      },
    ],
  });
  const { t } = useTranslation();

  const complete = async (id: string, optIn: boolean) => {
    if (optInLoading || optOutLoading) return;
    try {
      if (optIn) {
        return optInTextNotifications({
          variables: {
            objectId: id
          }
        })
          .then(throwFirstError);
      } else {
        return optOutTextNotifications({
          variables: {
            objectId: id
          }
        })
          .then(throwFirstError);
      }
    } catch (error) {
      if (VITE_STAGE !== 'production' && error instanceof Error) {
        console.error(error.message);
      }
      requestToast(t("An error occurred. Please try again later."), "ERROR");
    }
  };

  return complete;
};