import {
  Session,
  SessionStatus,
  Design,
  BOOKING_TYPES,
} from "../data/types";
import { unpackDesignNodes } from "./design";

export const MODE_TYPES = {
  BOOK: 'book',
  QUEUE: 'queue'
}

export function isHeldTimeSlot(session: Session): boolean {
  const booking_type = session?.booking?.type;
  return booking_type === BOOKING_TYPES.HELD;
}

export function isReservedBooking(session: Session): boolean {
  const booking_type = session?.booking?.type;
  return booking_type === BOOKING_TYPES.RESERVED;
}

export function isQueued(session: Session): boolean {
  const booking_type = session?.booking?.type;
  return booking_type === BOOKING_TYPES.QUEUED;
}

export function isCheckedIn(session: Session): boolean {
  const session_statuses = session?.session_statuses || [];
  return !!session_statuses.find(
    (session_status: SessionStatus) => session_status.type === "checked_in"
  );
}
export function isAssigned(session: Session): boolean {
  const session_statuses = session?.session_statuses || [];
  return !!session_statuses.find(
    (session_status: SessionStatus) => session_status.type === "assigned"
  );
}
export function isProcessing(session: Session): boolean {
  const session_statuses = session?.session_statuses || [];
  return !!session_statuses.find(
    (session_status: SessionStatus) => session_status.type === "processing"
  );
}

export function isMobileNotifiedOfCheckIn(session: Session): boolean {
  const session_statuses = session?.session_statuses || [];
  return !!session_statuses.find(
    (session_status: SessionStatus) =>
      session_status.type === "mobile_notified_check_in"
  );
}
export function isPickedUp(session: Session): boolean {
  const session_statuses = session?.session_statuses || [];
  return !!session_statuses.find(
    (session_status: SessionStatus) => session_status.type === "pickup"
  );
}
export function isCompleted(session: Session): boolean {
  const session_statuses = session?.session_statuses || [];
  return !!session_statuses.find(
    (session_status: SessionStatus) => session_status.type === "completed"
  );
}

export function getFirstDesign(session: Session): Design | null {
  const designs = unpackDesignNodes(session);
  return designs?.length > 0 ? designs[0] : null;
}

export function getDesigns(session?: Session): Array<Design> {
  if (!session) return [];
  return unpackDesignNodes(session);
}

export function getDesign(id: string, session: Session): Design | undefined {
  const designs = unpackDesignNodes(session);

  return designs.find((design: Design) => design.id === id);
}

export const getSessionStatus = (session: Session) => {
  const nextSessionStatuses = session?.session_statuses?.sort((a, b) => {
    return (b.timestamp || 0) - (a.timestamp || 0);
  })
  return nextSessionStatuses?.pop()?.type;
};

export function isPredesignComplete(design: Design | null): boolean {
  return (design?.inspirations || []).length > 0;
}

export const filterSessionsByStoreExperience = (sessions: any[] = [], storeId: string | null, experienceId: string | null) => {
  if (!storeId || !experienceId) return sessions;
  return sessions.filter((session) => {
    return session.store_id === storeId && session.experience_id === experienceId;
  });
}