import {ReactNode} from "react";

export default function CheckBox({checked, onChange, label}: {
  checked: boolean,
  onChange: (value: boolean) => void,
  label: ReactNode
}) {

  return (
    <button
      className={'flex items-center pt-1'}
      onClick={() => onChange(!checked)}
    >
      <div className={'w-10 h-10 flex-shrink-0 border border-black flex justify-center items-center rounded-xl'}>
        {checked ? <CheckMark/> : null}
      </div>
      <label className={'ml-3 text-nearblack text-left leading-[95%]'}>
        {label}
      </label>
    </button>
  );
}

function CheckMark() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="15"
      fill="none"
      viewBox="0 0 19 15"
      className={'w-full h-full p-[9px]'}
    >
      <path
        fill="#000"
        d="M17.716.251c.673-.748 1.743.343 1.07 1.091-3.73 4.302-7.49 8.542-11.25 12.812-.183.218-.367.406-.55.624-.275.311-.795.28-1.07 0-1.895-1.84-3.79-3.71-5.686-5.55-.703-.685.367-1.807 1.07-1.09a761.587 761.587 0 015.105 4.988C10.195 8.824 13.956 4.553 17.716.25z"
      ></path>
    </svg>
  );
}