import {t} from "i18next";
import {CleaningIcon} from "./icons.tsx";
import {useMoney} from "@hooks/money.ts";
import {ReactNode, useMemo} from "react";
import Section from "./section";
import ListItem from "./list-item.tsx";
import {useGetDiscountValue, usePrimaryService} from "./cleaning-pricing.hooks.ts";

interface CleaningService {
	price: number;
	memberDiscount: number;
	icon: ReactNode;
}

export const CLEANING_SERVICES: CleaningService = {
	price: 15,
	memberDiscount: 5,
	icon: <CleaningIcon/>,
}

export default function CleaningSection() {
	const primaryService = usePrimaryService();
	const {locale, moneyFormat} = useMoney();
	const formattedDiscount = useGetDiscountValue();
	const formattedStandard = useMemo(() => {
		if (!primaryService) {
			return '';
		}

		return moneyFormat({
			amount: primaryService?.prices?.[0].amount,
			currency: primaryService?.prices?.[0]?.currency,
			maximumFractionDigits: 0,
			minimumFractionDigits: 0,
		});
	}, [locale, primaryService]);

	return (
		<Section title={t("Cleaning Service")} className={'mt-4'}>
			<ListItem icon={CLEANING_SERVICES.icon}>
				<div>
					<div>
						{t("Standard Price")}
					</div>
					<div>
						{t("Member Price")}
					</div>
				</div>

				<div>
					<div>
						{formattedStandard}
					</div>
					<div>
						{formattedDiscount}
					</div>
				</div>
			</ListItem>
		</Section>
	)
}
