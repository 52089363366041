const {
  VITE_NBY_API_BASE_URL = "https://api.reserve.development.nikesportpulse.com",
  VITE_STAGE = "local",
  VITE_OIDC_CLIENT_ID,
  VITE_OIDC_URL = "https://accounts.nike.com",
  VITE_ANALYTICS_ENDPOINT = 'https://api.analytics.development.nike-connect.com/ingest',
  VITE_ANALYTICS_CLIENT_SECRET,
} = import.meta.env;

const NBY_TOKEN_TYPE = "RESERVE_API";
const DEFAULT_LANGUAGE = 'en';
const LATE_CHECK_IN_MILLIS = 1800000;
const ALLOWED_SESSION_HOLD_MINS = 10;

// IF YOU CHANGE THIS, CHANGE IT IN TAILWIND.CONFIG.JS
const DESKTOP_PHONE_WIDTH = 384;
const GRID_FIT = 11;
const GRID_HEIGHT_FACTOR = 40;

export {
  VITE_NBY_API_BASE_URL,
  VITE_STAGE,
  VITE_OIDC_CLIENT_ID,
  VITE_OIDC_URL,
  VITE_ANALYTICS_ENDPOINT,
  VITE_ANALYTICS_CLIENT_SECRET,
  NBY_TOKEN_TYPE,
  DEFAULT_LANGUAGE,
  LATE_CHECK_IN_MILLIS,
  ALLOWED_SESSION_HOLD_MINS,
  GRID_FIT,
  GRID_HEIGHT_FACTOR,
  DESKTOP_PHONE_WIDTH
}