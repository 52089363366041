import { useTranslation } from "react-i18next";
import SimpleSuccess from "../simple-success";

export default function Confirmed() {
  const { t } = useTranslation();

  return (
    <SimpleSuccess
      route={"/session-manager"}
      label={t("Booking Confirmed!")}
    />
  );
}
