import { useState, useEffect } from "react";

import checkmarkBlack from "../../../assets/icons/check-black.svg";
import { useAnalytics } from "@hooks/analytics";
import { ExperienceProduct, Inspiration } from "../../../data/types";

export default function ProductInspirationSelector({
  handleSelectInspiration = () => null,
  inspirationSelected,
  product
}: {
  inspirationSelected?: Array<Inspiration>;
  handleSelectInspiration: (value: Array<Inspiration>) => void;
  product?: ExperienceProduct;
}) {
  const analytics = useAnalytics();
  const [selectedInspirations, setSelectedInspirations] = useState<
    Array<Inspiration>
  >([]);

  useEffect(() => {
    if (inspirationSelected) setSelectedInspirations(inspirationSelected);
  }, [inspirationSelected]);

  return (
    <div className="mt-5 flex flex-1 flex-col justify-between overflow-scroll">
      <div>
        <div className="grid grid-cols-2 gap-2 px-5">
          {(product?.inspirations || []).map((inspiration) => (
            <button
              key={`${inspiration.id}`}
              className="relative"
              onClick={() => {
                if (!selectedInspirations.find((el) => el.id === inspiration.id)) {
                  setSelectedInspirations([
                    ...selectedInspirations,
                    inspiration,
                  ]);
                  handleSelectInspiration([
                    ...selectedInspirations,
                    inspiration,
                  ]);
                  analytics.setEvent({
                    type: 'inspiration_selector',
                    value: inspiration.id,
                    action: 'selected'
                  });
                } else {
                  setSelectedInspirations(
                    selectedInspirations.filter((el) => el.id !== inspiration.id)
                  );
                  handleSelectInspiration(
                    selectedInspirations.filter((el) => el.id !== inspiration.id)
                  );
                  analytics.setEvent({
                    type: 'inspiration_selector',
                    value: inspiration.id,
                    action: 'removed'
                  });
                }
              }}
            >
              <img className="" src={inspiration.image_url} />
              <div
                className={`align-center absolute bottom-2 right-2 flex h-7 w-7 justify-center rounded-full border-2 border-white p-1.5 ${selectedInspirations.find((el) => el.id === inspiration.id)
                  ? "bg-white"
                  : ""
                  }`}
              >
                {selectedInspirations.find((el) => el.id === inspiration.id) && (
                  <img src={checkmarkBlack} />
                )}
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
