import Button from "@components/button.tsx";
import { useUser } from "@hooks/user";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

export function ButtonSection() {
  const { user } = useUser();
  const navigate = useNavigate();

  function handleLogin() {
    navigate('/sign-in');
  }

  function handleConfirm() {
    navigate('/confirm');
  }

  function handleGuest() {
    navigate('/guest-sign-in');
  }

  return (
    <div className={"m-2 flex-shrink-0 h-[20vh]"}>
      {
        user ? (
          <Button
            onClick={handleConfirm}
            theme={"light"}
            // disabled={!selectedTimeSlot}
            fullWidth
            track={{
              type: "book_continue_button_clicked"
            }}
          >
            {t("Continue")}
          </Button>
        ) : (
          <>
            <Button
              onClick={handleLogin}
              theme={"light"}
              // disabled={!selectedTimeSlot}
              fullWidth
              track={{
                type: "book_continue_button_clicked"
              }}
            >
              {t("Login or Signup As Member")}
            </Button>

            <button
              className={"underline font-bold text-center block w-full mt-4 mb-8"}
              onClick={handleGuest}
            >
              {t('Or Book as Guest')}
            </button>
          </>
        )
      }
    </div>
  );
}