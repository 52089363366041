import { motion } from "framer-motion";
import React, { ReactNode } from "react";

interface TransitionRevealProps {
  index: number;
  children: ReactNode;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  stagger?: number;
  initialDelay?: number;
}

export default function TransitionReveal({
  index,
  stagger = 0.1,
  initialDelay = 0.5,
  children,
  ...rest
}: TransitionRevealProps) {
  return (
    <motion.div
      key={`reveal-${index}`}
      initial={{
        opacity: 0,
        scale: 0.4,
      }}
      animate={{
        opacity: 1,
        scale: 1,
      }}
      exit={{
        opacity: 0,
        scale: 0,
      }}
      transition={{
        delay: index * stagger + initialDelay,
      }}
      {...rest}
    >
      {children}
    </motion.div>
  );
}
