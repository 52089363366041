import { gql } from "@apollo/client";

export const CREATE_SESSIONS = gql`
  mutation AddSessions($objects: [AddSessionInput!]!) {
    addSessions(objects: $objects) {
      objects {
        id
        type
        store_id
        experience_id
        session_statuses {
          id
          type
          timestamp
        }
        booking {
          type
          start_date
          end_date
        }
        designs {
          lastEvaluatedKey
          edges {
            node {
              id
              product {
                id
              }
              session_id
              inspirations {
                id
                image_url
              }
              product_size {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_GUEST_SESSIONS = gql`
    mutation AddGuestSession($object: AddGuestSessionInput!, $g_email: String!) {
        addGuestSession(object: $object, g_email: $g_email) {
          session {
            id
            g_id
          }
          access_token {
            token
            expires
          }
        }
    }
`;

export const UPDATE_SESSION = gql`
  mutation UpdateSession($objectId: ID!, $object: UpdateSessionInput!) {
    updateSession(objectId: $objectId, object: $object) {
      id
      type
      store_id
      experience_id
      session_statuses {
        id
        type
        timestamp
      }
      booking {
        type
        start_date
        end_date
      }
      designs {
        lastEvaluatedKey
        edges {
          node {
            id
            product {
              id
            }
            session_id
            product_size {
              id
              name
            }
            inspirations {
              id
              image_url
            }
            session {
              id
            }
          }
        }
      }
    }
  }
`;

export const HOLD_SESSION = gql`
  mutation HoldSession($object: AddPublicSessionInput!) {
    holdSession(object: $object) {
      booking {
        end_date
        start_date
        type
      }
      id
      type
      store_id
      experience_id
      created_date
      updated_date
    }
  }
`;

export const DELETE_HELD_SESSION = gql`
  mutation DeleteHeldSession($objectId: ID!) {
    deleteHeldSession(objectId: $objectId) {
      id
    }
  }
`;

// WHEN CALLING THIS MUTATION, ALSO RESET THE APOLLO PRIVATE CLIENT CACHE
export const DELETE_SESSIONS = gql`
  mutation Mutation($objectIds: [ID!]!) {
    deleteSessions(objectIds: $objectIds) {
      deleted {
        id
        deleted_design_ids
      }
    }
  }
`;

export const OPT_IN_TEXT_NOTIFICATIONS = gql`
  mutation OptInTextNotifications($objectId: ID!) {
    optInTextNotifications(objectId: $objectId) {
      id
    }
  }
`;

export const OPT_OUT_TEXT_NOTIFICATIONS = gql`
  mutation OptOutTextNotifications($objectId: ID!) {
    optOutTextNotifications(objectId: $objectId) {
      id
    }
  }
`;