import TextField from "@components/text-field.tsx";
import {t} from "i18next";
import CheckBox from "@components/checkbox.tsx";
import {Trans} from "react-i18next";
import {useExperience} from "@hooks/experience.tsx";
import {useContext} from "react";
import {AppContext} from "../../context/app";
import {Store} from "../../data/types.ts";

interface GuestFormProps {
  name: string;
  onNameChange: (value: string) => void;
  email: string;
  onEmailChange: (value: string) => void;
  privacyChecked: boolean;
  onPrivacyChange: (value: boolean) => void;
}

export function GuestForm({email, name, onEmailChange, onNameChange, privacyChecked, onPrivacyChange}: GuestFormProps) {
  const {storeId, language} = useContext(AppContext);
  const store = useExperience()?.store as Store;

  return (
    <div className={"mt-12"}>
      <TextField
        label={t("Name")}
        value={name}
        onChange={onNameChange}
      />

      <TextField
        label={t("Email")}
        type={"email"}
        value={email}
        onChange={onEmailChange}
      />

      <CheckBox
        label={(
          <Trans
            i18nKey={'privacyPolicy'}
          >
            By checking this box, you confirm that you
            have read our <a
              className='border-b border-black'
              target="_blank"
              rel="noreferrer"
              href={`https://agreementservice.svs.nike.com/rest/agreement?agreementType=privacyPolicy&uxId=nike.cdm.sportpulse-oauth&mobileStatus=true&country=${store?.country_code}&language=${language}&requestType=redirect`}
            >{t('Privacy Policy')}</a>
          </Trans>
        )}
        checked={privacyChecked}
        onChange={onPrivacyChange}
      />
    </div>
  );
}
