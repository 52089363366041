import { gql } from "@apollo/client";

export const ADD_DESIGNS = gql`
  mutation AddDesigns($objects: [AddDesignInput!]!) {
    addDesigns(objects: $objects) {
      objects {
        id
        product {
          id
        }
        session_id
        product_size {
          id
          name
        }
        inspirations {
          id
          image_url
        }
        session {
          id
          session_statuses {
            id
            type
            timestamp
          }
          booking {
            type
            start_date
            end_date
          }
          designs {
            lastEvaluatedKey
            edges {
              node {
                id
                session_id
                product_size {
                  id
                  name
                }
                inspirations {
                  id
                  image_url
                }
                product_color {
                  id
                  name
                  hex
                  image_url
                }
                product {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_DESIGN = gql`
  mutation UpdateDesign($objectId: ID!, $object: UpdateDesignInput!) {
    updateDesign(objectId: $objectId, object: $object) {
      id
      product {
        id
        name
      }
      session_id
      product_size {
        id
        name
      }
      inspirations {
        id
        image_url
      }
      product_color {
        id
        name
        hex
        image_url
      }
    }
  }
`;

export const DELETE_DESIGNS = gql`
  mutation DeleteDesigns($objectIds: [ID!]!) {
    deleteDesigns(objectIds: $objectIds) {
      deleted {
        id
      }
    }
  }
`;

// WHEN CALLING THIS MUTATION, ALSO RESET THE APOLLO PRIVATE CLIENT CACHE
export const DELETE_MY_DESIGNS = gql`
  mutation DeleteMyDesigns {
    deleteMyDesigns {
      deleted {
        id
      }
    }
  }
`;

// WHEN CALLING THIS MUTATION, ALSO RESET THE APOLLO PRIVATE CLIENT CACHE
export const DELETE_ALL_DESIGNS = gql`
  mutation DeleteAllDesigns {
    deleteAllDesigns {
      deleted {
        id
      }
    }
  }
`;
