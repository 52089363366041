import { t } from "i18next";
import { useContext, useMemo, useState } from "react";
import Modal from "@components/modal.tsx";
import { useExperience } from "@hooks/experience.tsx";
import { Language, LANGUAGES } from "@lib/shared.ts";
import Button from "@components/button.tsx";
import Typography from "@components/typography.tsx";
import { AppContext } from "../context/app";

export function LanguageSelectorButton() {
  const [openLanguageModal, setOpenLanguageModal] = useState(false);

  return (
    <div>
      <Button
        variant={'outline'}
        size={'medium'}
        theme={'dark'}
        className={'w-44 py-1.5'}
        onClick={() => setOpenLanguageModal(true)}
      >
        {t('Language')}
      </Button>

      <SelectLanguageModal
        open={openLanguageModal}
        onClose={() => setOpenLanguageModal(false)}
      />
    </div>
  )
}

export function SelectLanguageModal({ open, onClose }: { open: boolean, onClose: () => void }) {
  const experience = useExperience();
  const { setLanguage } = useContext(AppContext);

  const supportedLangs: Array<Language> = useMemo(() => {
    return LANGUAGES.filter((lang) => {
      return experience?.supported_langs?.includes(lang.code);
    });
  }, [experience?.supported_langs])

  function selectLanguage(language: Language) {
    setLanguage(language);
    onClose();
  }

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      showClose={false}
      dissmissable={true}
      blurBg={false}
    >
      <div className={'flex justify-center gap-4 min-h-[30vh]'}>
        <div className='w-desktop-phone' style={{ padding: `30px 24px` }}>
          <Typography className='font-medium w-fit' size={24}>
            {t('Choose Your Language')}
          </Typography>
          <div className={'flex gap-2 mt-8'}>
            {
              supportedLangs.map((lang) => (
                <Button
                  size={'small'}
                  key={lang.code}
                  fullWidth
                  onClick={() => {
                    selectLanguage(lang)
                  }}
                >
                  {lang.name}
                </Button>
              ))
            }
          </div>
        </div>
      </div>
    </Modal>
  )
}