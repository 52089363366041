import {useEffect, useState} from "react";
import TermsModal from "@components/terms-modal.tsx";
import Status from "@components/status.tsx";
import {MainSection} from "./main-section.tsx";
import {ButtonSection} from "./button-section.tsx";

export default function CleaningPricing() {
  const [showTermsModal, setShowTermsModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowTermsModal(true);
    }, 500);
  }, []);

  function acceptTerms() {
    setShowTermsModal(false);
  }

  return (
    <Status containerClassName={'flex flex-col flex-1'}>
      <div className={'flex z-0 min-h-[80vh] flex-col flex-1 justify-between px-4 pt-6'}>
        <MainSection/>
        <ButtonSection/>
      </div>

      <TermsModal isOpen={showTermsModal} onAccept={acceptTerms}/>
    </Status>
  )
}

