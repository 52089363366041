import ConfirmModal from "@components/confirm-modal.tsx";
import {t} from "i18next";

export function SessionAlreadyExistsModal({onAccept, open}: { open: boolean, onAccept: () => void }) {
  return (
    <ConfirmModal
      title={t("Existing Session")}
      isOpen={open}
      confirm={t("OK")}
      onAccept={onAccept}
    >
      {t("You already have an existing session, check your email to manage?")}
    </ConfirmModal>
  );
}