import {createPortal} from "react-dom";
import {motion} from "framer-motion";
import Button, {ButtonProps} from "./button";
import {cn, DEFAULT_TRANSITION} from "@lib/shared";

type BottomButtonProps = ButtonProps & {
  containerClassName?: string;
};

const animated = {
  initial: {
    opacity: 0,
    y: 100,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 100,
  },
  transition: {
    ...DEFAULT_TRANSITION,
    duration: 0.2,
  },
};

export default function BottomButton({
                                       children,
                                       className,
                                       containerClassName,
                                       ...props
                                     }: BottomButtonProps) {
  return createPortal(
    <motion.div
      {...animated}
      className={cn("pointer-events-none fixed bottom-0 left-0 z-50 flex w-full items-end justify-center px-4", containerClassName)}
      style={{
        height: "20vh",
        pointerEvents: "none",
      }}
    >
      <Button
        className={`pointer-events-auto z-20 mb-6 ${className}`}
        {...props}
      >
        {children}
      </Button>
    </motion.div>,
    document.body
  );
}
