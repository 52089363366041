export default function ChevronIcon({ color = '#111111' }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.0332 15.5268L11.9992 8.56079L18.9662 15.5268"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
}
