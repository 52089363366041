import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider as BaseProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { refreshTokens } from "../rest";
import { VITE_NBY_API_BASE_URL, VITE_STAGE } from "../../constants";
import { isJsonString } from "@lib/shared";

interface ApolloProviderProps {
  children: React.ReactNode;
}

const privateLink = createHttpLink({
  uri: `${VITE_NBY_API_BASE_URL}/graphql/private`,
});

const publicLink = createHttpLink({
  uri: `${VITE_NBY_API_BASE_URL}/graphql/public`,
});

const logout = () => {
  localStorage.removeItem('tokens');
  window.location.replace("/sign-in");
}

// Add the auth header from session storage
const authLink = setContext(async (_, { headers }) => {
  const localStorageTokens = localStorage.getItem("tokens")
  const tokensString = isJsonString(localStorageTokens) && typeof localStorageTokens === 'string' ? localStorageTokens : "{}"
  const tokens = JSON.parse(tokensString);
  const access = tokens?.access || { expires: 0, token: "" };
  const refresh = tokens?.refresh || { expires: 0, token: "" };
  if (access?.expires <= Date.now() && refresh?.token && Date.now() < refresh?.expires) {
    // If access is expired, call refresh
    try {
      if (VITE_STAGE !== 'production') console.log("REFRESHING ACCESS TOKENS!");
      const newTokens = await refreshTokens(refresh.token);
      localStorage.setItem("tokens", JSON.stringify(newTokens));
    } catch (error) {
      logout();
    }
  } else if (refresh?.token && refresh.expires <= Date.now()) {
    // If refresh is expired, redirect to login page for the form factor in question
    logout();
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: access.token ? `Bearer ${access.token}` : "",
    },
  };
});


export const privateClient = new ApolloClient({
    link: authLink.concat(privateLink),
    cache: new InMemoryCache(),
  });
  

// For public calls (much more rare), you can use the
export const publicClient = new ApolloClient({
  link: publicLink,
  cache: new InMemoryCache(),
});

// Our app context will default to using the private client. For public calls, use the "usePublicQuery" and "usePublicMutation" custom hooks.
export default function ApolloProvider({ children }: ApolloProviderProps) {
  return <BaseProvider client={privateClient}>{children}</BaseProvider>
}
