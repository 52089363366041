import jwtDecode from "jwt-decode";
import { useEffect, useMemo, useState } from "react";
import storage from "@lib/storage.ts";

type Role = 'CUSTOMER_WRITE' | 'CUSTOMER_READ' | string;

interface Token {
  g_id: string
  g_email: string
  roles: Array<Role>
  is_access: boolean
  token_type: string
  session_id: string
  iat: number
  exp: number
}

export default function useDecodedToken(token?: string) {
  const [rawToken, setRawToken] = useState<string | undefined>(token);

  useEffect(() => {
    const t = storage.getItem<{ access: { token: string } }>('tokens', token ? { access: { token: token } } : undefined);
    setRawToken(t?.access?.token);
  }, []);

  return useMemo(() => {
    if (!rawToken) return null;
    return jwtDecode(rawToken) as Token;
  }, [rawToken]);
}