export interface ExperienceProductColor {
  hex?: string;
  id: string;
  image_url?: string;
  name: string;
  retail_availability?: RetailAvailability | null;
}

export interface RetailAvailability {
  total_quantity: number;
  availability_by_size: Array<AvailabilityBySize>;
}

export interface AvailabilityBySize {
  size: string;
  availability: string;
  gtin: string;
  quantity: number;
}

export interface ExperienceProductSize {
  id: string;
  name: string;
}

interface StartingPriceTranslation {
  lang: string;
  copy: string;
}
export interface ExperienceProduct {
  colors: Array<ExperienceProductColor>;
  currency: string;
  id: string;
  image_url: string;
  name: string;
  sizes: Array<ExperienceProductSize>;
  starting_price_translations: Array<StartingPriceTranslation>;
  style_code: string;
  type: string;
  inspirations: Array<Inspiration>;
  in_stock?: boolean;
}

export interface SessionStatus {
  id?: string;
  type?: string;
  timestamp?: number;
}

export interface Inspiration {
  id: string;
  image_url: string;
}

export type BookingType = "HELD" | "RESERVED";
export const BOOKING_TYPES = {
  QUEUED: "QUEUED",
  HELD: "HELD",
  RESERVED: "RESERVED",
};

export const SESSION_STATUS_TYPES = {
  QUEUED: "QUEUED",
  RESERVED: "RESERVED",
  CHECKED_IN: "CHECKED_IN",
  ASSIGNED: "ASSIGNED",
  IN_PROGRESS: "IN_PROGRESS",
  PROCESSING: "PROCESSING",
  PICKUP: "PICKUP",
  COMPLETED: "COMPLETED",
  NO_SHOW: "NO_SHOW",
  USER_CANCELLED: "USER_CANCELLED",
  NOTIFIED_RESERVED: "NOTIFIED_RESERVED",
};

export interface Booking {
  type?: BookingType;
  start_date?: number;
  end_date?: number;
}

export interface Design {
  id: string;
  product?: ExperienceProduct;
  product_size?: ExperienceProductSize;
  product_color?: ExperienceProductColor;
  inspirations?: Array<Inspiration>;
}

export const SESSION_TYPES = {
  NBY_PRODUCT_DESIGN_EXPERIENCE: "NBY_PRODUCT_DESIGN_EXPERIENCE",
  CLEAN_AND_CARE_EXPERIENCE: "CLEAN_AND_CARE_EXPERIENCE",
};

export type SessionType = keyof typeof SESSION_TYPES;

export interface Session {
  id: string;
  type: SessionType;
  created_date: number;
  updated_date: number;
  nu_id?: string;
  latest_status: string;
  session_statuses?: Array<SessionStatus>;
  booking?: Booking;
  designs?: {
    lastEvaluatedKey?: string;
    edges?: Array<{ node: Design }>;
  };
  store: Store;
  wait_time_at_session_upsert: number;
  text_notifications_allowed?: boolean;
  has_contact_number: boolean;
}

export interface Store {
  id: string
  timezone?: string
  locale?: string
  name?: string
  address?: string
  slug?: string
  country_code?: string
  full_name?: string;
  experiences?: Array<Experience>;
}

export interface Experience {
  id: string;
  type: string;
  active: boolean;
  products?: Array<ExperienceProduct>;
  store: Store;
  booking_enabled?: boolean;
  supported_langs: Array<string>;
  terms_and_conditions?: {
    id: string;
    urls: Array<{ lang: string; url: string }>;
  };
	service_items?: Array<ServiceItem>;
}

export interface ServiceItem {
	id: string;
	titles: Array<{ copy: string; lang: string }>;
	descriptions: Array<{ copy: string; lang: string }>;
	discounts?: Array<Discount>;
	tags?: Array<string>;
	prices: Array<Price>;
}

export interface Discount {
	type: string;
	discount_amount: number;
	currency: string;
}

export interface Price {
	currency: string;
	amount: number;
	price_type: string;
	icon: string;
	source: string;
	effective_date: number;
	modification_date: number;
	authored_date: number;
	updated_date: number;
}

export interface Token {
  expires: number;
  token: string;
}

export interface User {
  nu_id: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  next_session?: Session;
  last_session?: Session;
  tokens?: {
    access: Token;
    refresh: Token;
  };
}

export interface Guest {
  preferred_name: string;
  g_email: string;
  g_phone?: string;
}

export interface TimeSlot {
  id?: string;
  start_date: number;
  end_date?: number;
  type: string;
  expiration_date?: number;
  available_slots: number;
}
