import { motion } from 'framer-motion';
import Modal from "./modal";
import { DEFAULT_EASE } from '@lib/shared';
import Typography from './typography';
import { Trans, useTranslation } from 'react-i18next';
import Button from './button';
import { useContext } from 'react';
import { AppContext } from '../context/app';
import { usePublicQuery } from '@hooks/apollo';
import { STORE } from '../data/gql/queries/store';

export default function CookieModal({ isOpen, onAccept }: { isOpen: boolean, onAccept: () => void }) {
  const { t } = useTranslation();
  const { storeId, language } = useContext(AppContext);
  const { data } = usePublicQuery(STORE, {
    variables: {
      objectId: storeId
    }
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onAccept}
      showClose={false}
      blurBg={false}
    >
      <motion.div
        key='opt_in_modal'
        className="flex items-center justify-center w-full h-full"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          ease: DEFAULT_EASE,
          duration: 0.4,
          delay: 0,
        }}
      >
        <div className='w-desktop-phone' style={{ padding: `30px 24px` }}>
          <Typography className='font-medium w-fit' size={24}>
            {t('Your Cookie Settings')}
          </Typography>
          <Typography className='font-medium mt-4' size={16}>
            <Trans i18nKey="cookieLegalCopy">
              Nike uses cookies that are necessary for our website to function effectively. To get more information about these cookies and the processing of your personal data, check our <a
                className='border-b border-black'
                target="_blank"
                rel="noreferrer"
                href={`https://agreementservice.svs.nike.com/rest/agreement?agreementType=privacyPolicy&uxId=nike.cdm.sportpulse-oauth&mobileStatus=true&country=${data?.store?.country_code}&language=${language}&requestType=redirect`}
              >Privacy & Cookie Policy</a>
            </Trans>
          </Typography>
          <hr className='my-5 border-black opacity-20' />
          <Typography className='font-medium' size={16}>
            {t('Functional Cookies')}
          </Typography>
          <Typography className='mt-1 mb-4 opacity-60' size={12}>
            {t('These cookies are required for basic site functionality and therefore always enabled. These include cookies that allow you to be remembered as you explore the site within a single session or, if you request, from session to session. They help to make the bag and checkout process possible as well as assisting in security issues and confirming to regulations.')}
          </Typography>
          <Button
            onClick={onAccept}
            size='small'
            fullWidth={false}
          >
            {t('Understood')}
          </Button>
        </div>
      </motion.div>
    </Modal>
  )
}