import {useMoney} from "@hooks/money.ts";
import {ReactNode, useMemo} from "react";
import ListItem from "./list-item.tsx";
import {LacesIcon, SoleIcon} from "./icons.tsx";
import {t} from "i18next";
import Section from "./section.tsx";
import {useSecondaryServices} from "./cleaning-pricing.hooks.ts";
import {useLanguage} from "@hooks/language.ts";
import {ServiceItem} from "../../data/types.ts";

interface ServiceListItem {
  icon: ReactNode;
  title: string;
  price: number;
}

const SERVICE_LIST: Array<ServiceListItem> = [
  {
    title: 'Replacement\nLaces',
    price: 6.99,
    icon: <LacesIcon/>
  },
  {
    title: 'Replacement\nInsoles',
    price: 9.99,
    icon: <SoleIcon/>
  },
];

const ICONS: Record<string, ReactNode> = {
	'laces': <LacesIcon/>,
	'insoles': <SoleIcon/>
}

export function ServiceListing() {
	const secondaryServices = useSecondaryServices();
	const language = useLanguage();

	const services = useMemo<Array<ServiceListItem>>(() => {
		const s = secondaryServices || [];

		return s.map((service) => {
			const primary = service.titles?.find(s => s.lang === language)?.copy
			const price = service.prices?.[0];

			return {
				title: primary ?? service?.titles?.find(s => s.lang === 'en')?.copy ?? '',
				price: price.amount,
				icon: ICONS?.[price?.icon] ?? <div/>
			};
		});
	}, [secondaryServices, language]);

  return (
    <Section titleClass={'text-base'} title={t("Optional Services")} className={'mb-4'}>
      {
				services.map((service, idx,) => (
          <AdditionalServiceListItem
            key={idx}
            showBottomBorder={idx !== SERVICE_LIST.length - 1}
            service={service}
          />
        ))
      }
    </Section>
  )
}

function AdditionalServiceListItem({service, showBottomBorder}: {
  service: ServiceListItem,
  showBottomBorder?: boolean
}) {
  const {locale, moneyFormat} = useMoney();
  const formattedPrice = useMemo(() => {
    return moneyFormat({
      amount: service.price,
      currency: "EUR"
    });
  }, [locale]);

  return (
    <ListItem
      icon={service.icon}
      className={'text-lg'}
      showBottomBorder={showBottomBorder}
      containerClassName={'py-3'}
    >
      <div className={'leading-tight text-base whitespace-pre-wrap'}>
        {service.title}
      </div>
      <div className={'leading-tight flex-shrink-0 text-base'}>
        {formattedPrice}
      </div>
    </ListItem>
  )
}
