import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { useMemo } from "react";
import Typography from "@components/typography";
import Button from "@components/button";

// import { formatPhoneNumber } from "@lib/shared";
// import { useUser } from "@hooks/user";
import { Design } from "../../../data/types";

export default function SessionSummaryPrep({
  gridSize,
  design,
}: {
  gridSize: number;
  design?: Design;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const { user } = useUser();

  // const phoneFormatted = useMemo(() => {
  //   return formatPhoneNumber(user.phone);
  // }, [user.phone]);

  const height = design ? 5 * gridSize : 7 * gridSize;

  return (
    <div style={{ height: height - 2 }}>
      {!design && (
        <div className={"mx-3 py-4"}>
          <Typography
            size={24}
            font={"helvetica-now"}
            className={"font-medium text-nearblack"}
          >
            {t('Prepare For Your Session')}
          </Typography>

          <Typography
            className={"mt-4"}
            lineHeight={"24px"}
            size={16}
            font={"helvetica-now"}
          >
            {t('See what others have made and gather inspiration for your designs.')}
          </Typography>

          <Button
            size={"small"}
            onClick={() => navigate("/pre-design")}
            className={"mt-8"}
            track={{
              type: 'pre_design_get_started_button_clicked'
            }}
          >
            {t('Get Started')}
          </Button>
        </div>
      )}
      {design && (
        <div className={"mx-3 py-4"}>
          <Typography
            size={24}
            font={"helvetica-now"}
            className={"font-medium text-nearblack"}
          >
            {t("What's Next")}
          </Typography>

          <Typography size={16} font={"helvetica-now"} className={"mt-5"}>
            {t(`We'll contact you when your session is ready.`)}
          </Typography>
        </div>
      )}
    </div>
  );
}
