import { useQuery } from "@apollo/client";
import { STORE } from "../data/gql/queries/store";
import { publicClient } from "../data/providers/apollo";
import { useContext } from "react";
import { AppContext } from "../context/app.tsx";
import { Experience, Store } from "../data/types.ts";

export const useStore = (storeId: string, experienceId: string) => {
  const { data, loading, error } = useQuery(STORE, {
    variables: {
      objectId: storeId
    },
    skip: !storeId,
    client: publicClient
  });
  const experience = data?.store?.experiences?.find((experience: { id: string }) => {
    return experience.id === experienceId
  });

  return {
    store: data?.store,
    experience,
    loading: loading,
    error
  };
};