import { useTranslation } from 'react-i18next';
import Card from "@components/card";
import { ExperienceProduct } from "../data/types";

interface ProductCardProps {
  product: ExperienceProduct;
  onClick?: () => void;
  className: string;
  locale: string;
  disabled?: boolean;
}

export default function ProductCard({
  product,
  className = "",
  locale,
  disabled = false
}: ProductCardProps) {
  const { t } = useTranslation();
  const subtitle = (product?.starting_price_translations || []).find(el => {
    return el.lang === locale;
  });

  return (
    <Card
      title={product.name}
      subtitle={disabled ? t('Not Available') : subtitle?.copy}
      image={product.image_url}
      className={`${className}${disabled ? ' opacity-50' : ''}`}
    />
  );
}
