import { useState, useEffect } from "react";
import { ExperienceProduct, ExperienceProductColor } from "../../../data/types";
import checkmarkBlack from "../../../assets/icons/check-black.svg";

export default function ProductColorSelector({
  colors,
  colorSelected,
  handleSelectColor = () => null,
}: {
  product?: ExperienceProduct;
  colors: Array<ExperienceProductColor>;
  colorSelected?: ExperienceProductColor;
  handleSelectColor: (value: ExperienceProductColor) => void;
}) {
  const [selectedColor, setSelectedColor] = useState<ExperienceProductColor>();

  useEffect(() => {
    if (colorSelected) setSelectedColor(colorSelected);
  }, [colorSelected]);

  const selectedProductColor = colors?.find((el) => el.id === selectedColor?.id)
    || colors[0];
  const hasImages = !!colors?.find((el) => el.image_url);

  return (
    <div className="flex flex-1 flex-col justify-between">
      {colors.length > 0 && (
        <>
          {hasImages && <div className="mb-1 w-full bg-neutral-100">
            {selectedProductColor?.image_url && <img
              className="mb-16 w-full"
              src={selectedProductColor?.image_url}
            />}
          </div>}
          <div className={`flex ${hasImages ? 'overflow-scroll' : 'flex-wrap gap-10 justify-center mt-8'}`}>
            {colors?.map((color) => (
              <div
                key={color.id}
                className="relative flex flex-col items-center justify-center"
              >
                <div
                  onClick={() => {
                    setSelectedColor(color);
                    handleSelectColor(color);
                  }}
                  className={`mr-1 ${hasImages ? color.id === selectedColor?.id
                    ? "bg-neutral-200"
                    : "bg-neutral-100"
                    : 'drop-shadow'}`}
                >
                  {hasImages && color.image_url ? (
                    <img className="mb-6 h-36 max-w-fit" src={color.image_url} />
                  ) : (
                    <div className="mt-3 mb-3 flex justify-center items-center h-36 w-36">
                      <div className={`w-20 h-20 rounded-full`}
                        style={{
                          backgroundColor: color?.hex,
                        }}
                      />
                    </div>
                  )}
                  {!hasImages && <div
                    className={`align-center absolute flex h-7 w-7 justify-center rounded-full p-1.5 ${color.id === selectedColor?.id
                      ? "bg-white"
                      : ""
                      }`}
                    style={{
                      left: '50%',
                      top: '50%',
                      transform: 'translate(-50%, -50%)',
                      boxShadow: color.id === selectedColor?.id ? '0px 0px 8px 0px rgba(0, 0, 0, 0.25)' : 'none'
                    }}
                  >
                    {color.id === selectedColor?.id && (
                      <img src={checkmarkBlack} />
                    )}
                  </div>}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div >
  );
}
