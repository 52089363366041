import { useCallback, useContext, useMemo } from "react";
import { motion } from 'framer-motion';
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import Typography from "@components/typography";
import { usePublicQuery } from "@hooks/apollo";

import { AVAILABLE_SESSION_LIST } from "../../data/gql/queries/session";
import { AppContext } from "../../context/app";
import { TimeSlot } from "../../data/types";
import { DateSelection } from "./booking-calendar/booking-calendar";
import { useAnalytics } from "@hooks/analytics";
import { getDateTimeForDateString, simpleAmPmTimeFormat } from "@lib/datetime";
import { useSessionType } from "@hooks/experience";

export default function BookingTimeSelector({
  buttonsDisabled = false,
  onSelect,
  selectedTimeSlot,
  selectedDate,
  bookingWindowStartMs,
  bookingWindowEndMs,
  timezone,
  locale
}: {
  buttonsDisabled: boolean;
  onSelect: (slot: TimeSlot | null) => void;
  selectedTimeSlot: TimeSlot | null;
  timezone?: string;
  locale?: string;
  selectedDate: string;
  endDate?: DateSelection;
  bookingWindowStartMs?: number;
  bookingWindowEndMs?: number;
}) {
  const analytics = useAnalytics();
  const { storeId, experienceId } = useContext(AppContext);
  const { t } = useTranslation();
  const sessionType = useSessionType();

  const date = getDateTimeForDateString(selectedDate, timezone);

  const { data, loading } = usePublicQuery(AVAILABLE_SESSION_LIST, {
    variables: {
      store_id: storeId,
      experience_id: experienceId,
      date,
      session_type: sessionType
    },
    fetchPolicy: "network-only",
    // Poll so that your list updates when other users hold/release a slot
    pollInterval: 1000,
  });

  const availableSessionList = useMemo(() => {
    return (data?.availableSessionList?.objects || [])
      .filter((slot: TimeSlot) => {
        if (bookingWindowStartMs && bookingWindowEndMs) {
          if (
            slot?.start_date >= bookingWindowStartMs &&
            slot?.start_date <= bookingWindowEndMs
          ) {
            return slot.available_slots > 0 && slot.start_date > Date.now();
          }
          return false;
        }
        return slot.available_slots > 0 && slot.start_date > Date.now();
      });
  }, [data]);

  const isSelected = useCallback(
    (slot: TimeSlot) => {
      const selectedTime = selectedTimeSlot?.start_date;
      if (!selectedTime) {
        return false;
      }
      return selectedTime === slot?.start_date;
    },
    [selectedTimeSlot]
  );

  const handleSelect = (slot: TimeSlot) => {
    analytics.setEvent({
      type: 'selected_time_slot',
      value: slot?.start_date
    })
    onSelect(slot);
  }

  if (!loading && availableSessionList.length === 0) {
    return (
      <div className="flex flex-row justify-center">
        <Typography size={26} font={"helvetica-now"} className="font-bold">
          {t('No sessions available.')}
        </Typography>
      </div>
    );
  }

  return (
    <motion.div
      key='booking-time-selector'
      className="grid grid-cols-3 gap-1"
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
    >
      {availableSessionList.map((slot: TimeSlot, index: number) => (
        <button
          key={index}
          className={clsx(
            `border-2 text-neutral-900`,
            isSelected(slot) ? "border-neutral-700" : "border-neutral-200",
            "rounded-lg py-5 text-center",
            buttonsDisabled ? "opacity-50" : ""
          )}
          disabled={buttonsDisabled}
          onClick={() => handleSelect(slot)}
        >
          {index === 0 && (
            <Typography
              font={"helvetica-now"}
              size={10}
              className="h-0 font-medium text-[#1EAA52] -mt-2"
            >
              {t('Next Available')}
            </Typography>
          )}
          <Typography
            font={"helvetica-now"}
            size={14}
            className={clsx(
              "font-normal",
              index === 0 && "-mb-2 mt-3",
            )}
          >
            {simpleAmPmTimeFormat(slot.start_date, timezone, locale)}
          </Typography>
        </button>
      ))}
    </motion.div>
  );
}
