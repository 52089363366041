import { useTranslation } from "react-i18next";
import Typography from "../components/typography";

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <div className="flex h-full flex-row items-center justify-center bg-black text-white">
      <Typography size={28} font={"futura"}>
        404 - {t('NOT FOUND')}
      </Typography>
    </div>
  );
}
