import Typography from "./typography";
import { Theme } from "@lib/shared";
import { CSSProperties, ReactNode, useMemo } from "react";
import clsx from "clsx";
import { overrideTailwindClasses } from "tailwind-override";
import { useIsDesktop } from "@hooks/use-is-desktop";
import { useAnalytics } from "@hooks/analytics";

export interface ButtonProps {
  children: ReactNode;
  onClick: () => void;
  className?: string;
  theme?: Theme;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: "text" | "outline";
  size?: "normal" | "small" | "medium" | "xsmall";
  track?: any;
  style?: CSSProperties;
}

export default function Button({
  children,
  variant,
  fullWidth,
  onClick,
  className,
  theme = "light",
  size = "normal",
  disabled,
  track,
  style = {}
}: ButtonProps) {
  const isDesktop = useIsDesktop();
  const analytics = useAnalytics();
  const colorClasses = useMemo(() => {
    if (variant === "text") {
      return theme === "dark"
        ? "bg-transparent  text-white disabled:text-neutral-300"
        : "bg-transparent text-black disabled:text-neutral-400";
    }

    return theme === "dark"
      ? "bg-white text-black disabled:text-neutral-600 disabled:bg-neutral-400"
      : "bg-black text-neutral-50 disabled:text-neutral-400 disabled:bg-neutral-600";
  }, [theme, variant]);

  const variantClasses = useMemo(() => {
    if (variant === "outline") {
      return theme === "dark"
        ? "border-2 bg-white disabled:bg-transparent border-neutral-300 disabled:border-opacity-50 disabled:text-opacity-50"
        : "border-black";
    }

    return "border-0";
  }, [variant, theme]);

  const sizingClasses = useMemo(() => {
    if (size === "small" || size === 'xsmall') {
      return variant === "outline" ? "py-0 px-3" : "py-1 px-5";
    }
    if (size === "medium") {
      return "py-3 px-4";
    }
    return "px-6 py-4";
  }, [size, variant]);

  const fontClasses = useMemo(() => {
    if (size === "xsmall") {
      return "text-[12px] font-normal";
    }
    if (size === "small") {
      return "text-[16px] font-normal";
    }
    if (size === "medium") {
      return "text-base font-medium";
    }
    return "text-lg font-medium";
  }, [size]);

  let widthClasses = "";
  if (fullWidth && !isDesktop) {
    widthClasses = "w-full";
  } else if (isDesktop && size !== "small") {
    widthClasses = "w-desktop-phone-child";
  } else {
    widthClasses = "w-fit";
  }

  const handleClick = () => {
    if (track) analytics.setEvent(track);
    onClick();
  }

  return (
    <button
      className={overrideTailwindClasses(
        clsx([
          "rounded-full ring-0",
          colorClasses,
          variantClasses,
          sizingClasses,
          widthClasses,
          "hover:bg-opacity-80 active:opacity-50",
          className,
        ])
      )}
      style={{
        minWidth: variant === "text" ? undefined : "120px",
        ...style
      }}
      disabled={disabled}
      onClick={handleClick}
    >
      <Typography font={"helvetica-now"} className={fontClasses}>
        {children}
      </Typography>
    </button>
  );
}
