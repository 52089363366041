import {t} from "i18next";
import {useGetDiscountValue} from "./cleaning-pricing.hooks.ts";

export function Info() {
	const formatDiscount = useGetDiscountValue()

  return (
    <div className={'bg-[#D8FC00] rounded-lg px-4 py-3 my-4'}>
      <div className={'font-bold mb-1 leading-4'}>
        {t('Members get {{discount}} off cleaning service', {
          discount: formatDiscount,
        })}
      </div>
      <div className={'text-neutral-600 font-semibold leading-4'}>
        {t('Sign in or become a member to redeem')}
      </div>
    </div>
  );
}
