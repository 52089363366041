import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function requestToast(contents: string, type: string) {
  let bodyClassName;

  switch (type) {
    case "SUCCESS":
      bodyClassName = "text-green-500";
      break;
    case "INFO":
      bodyClassName = "text-blue-800";
      break;
    case "ERROR":
    default:
      bodyClassName = "text-red-500";
  }

  return toast(contents, {
    bodyClassName,
  });
}
