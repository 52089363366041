import { createAnalytics } from "analytics";
import { ReactNode, createContext, useState } from "react";
import { VITE_ANALYTICS_CLIENT_SECRET, VITE_ANALYTICS_ENDPOINT } from "../constants";

const AnalyticsContext = createContext<any>(null);

const AnalyticsContextProvider = ({ children }: { children: ReactNode }) => {
  const [client] = useState(
    createAnalytics({
      client_secret: VITE_ANALYTICS_CLIENT_SECRET,
      endpoint: VITE_ANALYTICS_ENDPOINT,
      batching: 100,
    })
  );

  return (
    <AnalyticsContext.Provider value={client}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export { AnalyticsContext, AnalyticsContextProvider };
