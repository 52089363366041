import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { MotionConfig } from "framer-motion";
import * as Sentry from "@sentry/react";
import { DEFAULT_TRANSITION } from "@lib/shared";
import "./index.css";
import AppApolloProvider from "./data/providers/apollo";
import { AppContextProvider } from './context/app';
import App from './App';
import "./i18n";
import { AnalyticsContextProvider } from './context/analytics';
import { VITE_STAGE } from './constants';

Sentry.init({
  dsn: "https://52fd628101c86baeb75b0f28364bc1d9@sentry.lab.nikesportpulse.com/3",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: VITE_STAGE
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <AppApolloProvider>
        <MotionConfig transition={DEFAULT_TRANSITION}>
          <AppContextProvider>
            <AnalyticsContextProvider>
              <App />
            </AnalyticsContextProvider>
          </AppContextProvider>
        </MotionConfig>
      </AppApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
);
