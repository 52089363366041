import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import ProductCard from "@components/product-card";
import TransitionReveal from "@components/transition-reveal";
import Typography from "@components/typography";
import { ExperienceProduct } from "../../../data/types";

const CATEGORY_SORT = ["APPAREL", "ACCESSORIES", "FOOTWEAR"];

export default function ProductSelectorProducts({
  handleSelectProduct = () => null,
  productSelected,
  products,
  locale
}: {
  handleSelectProduct: (value: ExperienceProduct) => void;
  productSelected: ExperienceProduct | undefined;
  products: Array<ExperienceProduct>;
  locale: string;
}) {
  const [selectedProduct, setSelectedProduct] = useState<ExperienceProduct | null>(null);
  const [categories, setCategories] = useState<Array<string>>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (productSelected) setSelectedProduct(productSelected);
    const temp = products.map((el) => el.type);
    temp.sort((a, b) => CATEGORY_SORT.indexOf(a) - CATEGORY_SORT.indexOf(b));
    setCategories([...new Set(temp)]);
  }, [productSelected, products]);

  return (
    <>
      <div>
        {categories.map((category, i) => (
          <div key={`${category}${i}`}>
            <Typography className={"mt-5 px-5 font-medium"} size={16}>
              {t(category)}
            </Typography>
            <div className="mb-6 mt-4 flex grid h-full w-full grid-cols-2 flex-wrap justify-between gap-3 px-5">
              {products
                .filter((el) => el.type === category)
                .map((product, i) => (
                  <TransitionReveal
                    key={`${category}${product}${i}`}
                    index={i}
                    onClick={() => {
                      if (!product.in_stock) return;
                      setSelectedProduct(product);
                      handleSelectProduct(product);
                    }}
                  >
                    <ProductCard
                      product={product}
                      locale={locale}
                      disabled={!product.in_stock}
                      className={`h-full overflow-hidden rounded-lg border-2 ${product.id === selectedProduct?.id
                        ? "border-black"
                        : ""
                        }`
                      }
                    />
                  </TransitionReveal>
                ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
