import { useState, useEffect, useContext, useMemo } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PageTransition from "@components/page-transition";
import { getDesign, getFirstDesign } from "@lib/session";
import BackButton from "@components/back-button";
import BottomButton from "@components/bottom-button";
import Typography from "@components/typography";
import MultiToggle from "@components/multi-toggle";
import {
  useSaveDesign,
  useUpdateDesign,
} from "@hooks/design";

import ProductSelectorProducts from "./product-selector/product-selector";
import ProductSelectorSize from "./product-size-selector/product-size-selector";
import ProductSelectorColor from "./product-color-selector/product-color-selector";
import ProductSelectorInspiration from "./product-inspiration-selector/product-inspiration-selector";
import { Design, ExperienceProduct, Inspiration, ExperienceProductColor, ExperienceProductSize } from "../../data/types";
import { useUser } from "@hooks/user";
import { STORE } from "../../data/gql/queries/store";
import { usePublicQuery } from "@hooks/apollo";
import { AppContext } from "../../context/app";

export default function PreDesign() {
  const navigate = useNavigate();
  const { designId } = useParams();
  const { storeId, experienceId, language } = useContext(AppContext);
  const { saveDesign, designSaving } = useSaveDesign();
  const { updateDesign, designUpdating } = useUpdateDesign();
  const { user, loading } = useUser();
  const { data } = usePublicQuery(STORE, {
    variables: {
      objectId: storeId
    }
  });
  const { t } = useTranslation();
  const experience = data?.store?.experiences?.find((experience: { id: string }) => {
    return experience.id === experienceId
  });
  const inspirationEnabled = experience?.inspiration_enabled;
  const products = useMemo(() => (experience?.products || []).map((el: ExperienceProduct) => {
    const inStock = el.style_code
      ? (el.colors.reduce((acc, color) => {
        return acc + (color.retail_availability?.total_quantity || 0);
      }, 0) > 0)
      : true;

    return {
      ...el,
      in_stock: inStock
    }
  }), [experience?.products]);
  const nextSession = user?.next_session;

  const [design, setDesign] = useState<Design | null>();
  const [tabSelected, setTabSelected] = useState("Product");
  const [options, setOptions] = useState({
    Product: true,
    Color: false,
    Size: false,
  });
  const [productSelected, setProductSelected] = useState<ExperienceProduct>();
  const [availableSizes, setAvailableSizes] = useState<Array<ExperienceProductSize>>([]);
  const [availableColors, setAvailableColors] = useState<Array<ExperienceProductColor>>([]);
  const [sizeSelected, setSizeSelected] = useState<ExperienceProductSize>();
  const [colorSelected, setColorSelected] = useState<ExperienceProductColor>();
  const [inspirationSelected, setInspirationSelected] = useState<Array<Inspiration>>(
    []
  );

  const handleToggleSelect = (selected: string) => {
    setTabSelected(t(`${selected}`));
  };

  const handleSelectProduct = (selected: ExperienceProduct) => {
    const filteredColors = (selected?.colors || []).filter((el) => {
      // filter out SIM product colors not in stock
      const inStock = selected?.style_code
        ? ((el?.retail_availability?.total_quantity || 0) > 0)
        : true;
      return (el.image_url || el.hex) && inStock;
    });
    const isSIM = !!selected?.style_code;
    const sizes = isSIM ? filteredColors.length > 0 ? selected?.sizes : [] : (selected?.sizes || []);
    setProductSelected(selected);
    setAvailableSizes(sizes);
    setAvailableColors(filteredColors);
    setOptions({
      Product: true,
      Color: (filteredColors).length > 0,
      Size: (sizes).length > 0,
    });
    if (selected?.id !== design?.product?.id) {
      setSizeSelected(undefined);
      setColorSelected(undefined);
      setInspirationSelected([]);
    }
  };

  const handleSelectSize = (selected: ExperienceProductSize) => {
    setSizeSelected(selected);
  };

  const handleSelectColor = (selected: ExperienceProductColor) => {
    if (!selected) return;
    let sizes = productSelected?.sizes;
    if (selected.retail_availability) {
      sizes = (productSelected?.sizes || []).filter((el) => {
        const sizeAvailability = selected
          .retail_availability?.availability_by_size?.find((avail) => {
            return el.name === avail?.size;
          });
        return (sizeAvailability?.quantity || 0) > 0;
      })
    }
    setOptions({
      Product: true,
      Color: true,
      Size: (sizes || []).length > 0,
    });
    setAvailableSizes(sizes || []);
    setColorSelected(selected);
  };

  const handleSelectInspiration = (selected: Array<Inspiration>) => {
    setInspirationSelected(selected?.map((el: Inspiration) => ({
      id: el.id,
      image_url: el.image_url
    })));
  };

  useEffect(() => {
    const temp = nextSession && designId ? getDesign(designId, nextSession) : null;
    if (temp) {
      const selected = products.filter((prod: ExperienceProduct) => prod.id === temp?.product?.id)[0];
      const filteredColors = (selected?.colors || []).filter((el: ExperienceProductColor) => {
        // filter out SIM product colors not in stock
        const inStock = selected?.style_code
          ? ((el?.retail_availability?.total_quantity || 0) > 0)
          : true;
        return (el.image_url || el.hex) && inStock;
      });
      const isSIM = !!selected?.style_code;
      const sizes = isSIM ? filteredColors.length > 0 ? selected?.sizes : [] : (selected?.sizes || []);
      setDesign(temp);
      setProductSelected(selected);
      setAvailableSizes(sizes);
      setAvailableColors(filteredColors);
      setOptions({
        Product: true,
        Color: (filteredColors).length > 0,
        Size: sizes.length > 0,
      });
      setSizeSelected(temp?.product_size);
      setColorSelected(
        selected?.colors?.filter(
          (color: ExperienceProductColor) => color.name === temp?.product_color?.name
        )[0]
      );
      setInspirationSelected(temp?.inspirations?.map((el: Inspiration) => ({
        id: el.id,
        image_url: el.image_url
      })) || []);
    }
  }, [nextSession]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tabSelected]);

  if (!loading && !nextSession) {
    return <Navigate to={"/intro"} />;
  }

  const handleContinue = async () => {
    if (tabSelected === 'Product') {
      if (availableColors.length) {
        setTabSelected("Color");
        return;
      } else if (availableSizes.length) {
        setTabSelected("Size");
        return;
      } else if ((productSelected?.inspirations || []).length && inspirationEnabled) {
        setTabSelected("Inspiration");
      }
    }
    if (tabSelected === 'Color' && colorSelected) {
      if (availableSizes.length) {
        setTabSelected("Size");
        return;
      } else if ((productSelected?.inspirations || []).length && inspirationEnabled) {
        setTabSelected("Inspiration");
      }
    }
    if (tabSelected === 'Size' && sizeSelected && inspirationEnabled) {
      if ((productSelected?.inspirations || []).length && inspirationEnabled) {
        setTabSelected("Inspiration");
        return;
      }
    }

    if (
      nextSession &&
      ((productSelected?.colors || []).length === 0 || colorSelected) &&
      ((productSelected?.sizes || []).length === 0 || sizeSelected) &&
      productSelected
    ) {
      if (!design) {
        await saveDesign(nextSession?.id, {
          product_size_id: sizeSelected?.id || 'product_size:none',
          product_color_id: colorSelected?.id || 'product_color:none',
          product_id: productSelected.id,
          inspiration_ids: inspirationSelected.map(insp => insp.id),
        });
      } else {
        await updateDesign(design.id, {
          product_size_id: sizeSelected?.id || 'product_size:none',
          product_color_id: colorSelected?.id || 'product_color:none',
          product_id: productSelected.id,
          inspiration_ids: inspirationSelected.map(insp => insp.id),
        });
      }
      navigate("/session-manager");
    }
  }

  return (
    <PageTransition
      className="z-0 flex flex-col items-center justify-end bg-white bg-cover bg-center bg-no-repeat text-black"
      type={{
        in: "fade",
        out: "fade",
      }}
    >
      <div className={"z-20 flex h-full w-full flex-col pt-10"}>
        <div className="px-5">
          <BackButton
            onClick={() => {
              if (tabSelected === "Product") {
                navigate(-1);
              } else if (tabSelected === "Color") {
                setColorSelected(undefined);
                setTabSelected("Product");
              } else if (
                tabSelected === "Size" &&
                availableColors.length > 0
              ) {
                setSizeSelected(undefined);
                setTabSelected("Color");
              } else if (tabSelected === "Size") {
                setSizeSelected(undefined);
                setTabSelected("Product");
              } else if (
                tabSelected === "Inspiration" &&
                availableSizes.length > 0
              ) {
                setTabSelected("Size");
              } else if (
                tabSelected === "Inspiration" &&
                availableColors.length > 0
              ) {
                setTabSelected("Color");
              } else if (tabSelected === "Inspiration") {
                setTabSelected("Product");
              } else setTabSelected('Product');
            }}
          />
        </div>
        <div className={`mt-3 px-5 text-black`}>
          <Typography className={"font-medium"} size={24}>
            {t('Prepare for Your Session')}
          </Typography>
        </div>
        <div className={`mt-2 px-5 text-gray-500`}>
          <Typography className={"font-normal"} size={16}>
            {t('Select your product for customization, specify color and size and gather inspiration for your session.')}
          </Typography>
        </div>
        <div
          className={`${tabSelected === "Inspiration" ? "" : "mt-8 border-b"
            } px-5`}
        >
          {tabSelected !== "Inspiration" && (
            <MultiToggle
              options={options}
              active={tabSelected}
              onSelect={(selected: any) => handleToggleSelect(selected)}
              className="flex flex-row font-medium "
            />
          )}
        </div>
        <div className="pb-24">
          {tabSelected === "Product" && (
            <ProductSelectorProducts
              productSelected={productSelected}
              handleSelectProduct={(selected) => handleSelectProduct(selected)}
              products={products}
              locale={language}
            />
          )}
          {tabSelected === "Size" && (
            <ProductSelectorSize
              sizes={availableSizes}
              sizeSelected={sizeSelected}
              handleSelectSize={(selected) => handleSelectSize(selected)}
            />
          )}
          {tabSelected === "Color" && (
            <ProductSelectorColor
              colors={availableColors}
              colorSelected={colorSelected}
              handleSelectColor={(selected) => handleSelectColor(selected)}
            />
          )}
          {tabSelected === "Inspiration" && (
            <ProductSelectorInspiration
              product={productSelected}
              inspirationSelected={inspirationSelected}
              handleSelectInspiration={(selected) =>
                handleSelectInspiration(selected)
              }
            />
          )}
        </div>
      </div>
      <BottomButton
        onClick={handleContinue}
        theme={"light"}
        fullWidth
        disabled={designSaving || designUpdating}
        track={{
          type: 'pre_design_continue_button_clicked'
        }}
      >
        {t('Continue')}
      </BottomButton>
    </PageTransition>
  );
}
