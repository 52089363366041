import {useExperience} from "@hooks/experience.tsx";
import {useEffect, useMemo} from "react";
import {CLEANING_SERVICES} from "./cleaning-section.tsx";
import {useMoney} from "@hooks/money.ts";

export function useServices() {
	const experience = useExperience();

	return useMemo(() => {
		return experience?.service_items || [];

	}, [experience]);
}

export function usePrimaryService() {
	const services = useServices();

	return useMemo(() => {
		return services.find((service) => service.tags?.includes("primary_service"));
	}, [services]);
}

export function useSecondaryServices() {
	const services = useServices();

	return useMemo(() => {
		return services.filter((service) => service.tags?.includes("secondary_service"));
	}, [services]);
}

export function useGetDiscountValue() {
	const primaryService = usePrimaryService();
	const {locale, moneyFormat} = useMoney();

	return useMemo(() => {
		if (!primaryService) {
			return '';
		}

		return moneyFormat({
			amount: CLEANING_SERVICES.price - CLEANING_SERVICES.memberDiscount,
			currency: "EUR",
			maximumFractionDigits: 0,
			minimumFractionDigits: 0,
		});
	}, [locale]);
}
