import Typography from "../components/typography";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PageTransition from "../components/page-transition";
import CircleCheckIcon from "@components/icon-circle-check";

export default function SimpleSuccess({
  route,
  label = "Success",
}: {
  route: string;
  label: string;
}) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      navigate(route);
    }, 2000);
  }, [navigate, route]);

  return (
    <PageTransition
      type={"vapor"}
      className={
        "flex h-full w-full flex-col items-center justify-center bg-white font-medium"
      }
      style={{ gap: "4.926vh" }}
    >
      <CircleCheckIcon />
      <Typography
        size={location.pathname.match(/kiosk/) ? 60 : 25}
        style={{ color: "#128A09" }}
      >
        {label}
      </Typography>
    </PageTransition>
  );
}
