import { Store } from "../../../data/types";
import { useTranslation } from "react-i18next";
import {cn} from "@lib/shared.ts";
import {useLanguage} from "@hooks/language.ts";

export default function SessionSummaryLegal({ store, gridSize }: { store: Store, gridSize: number }) {
  const language = useLanguage();
  const { t } = useTranslation();

  return (
    <div className={cn("flex justify-between p-4", language === 'de' && 'flex-col justify-start gap-3')} style={{
      height: 3 * gridSize - 2,
    }}>
      <div className='w-fit'>
        <a
          className='border-b border-black text-base h-fit'
          target="_blank"
          rel="noreferrer"
          href={`https://agreementservice.svs.nike.com/rest/agreement?agreementType=privacyPolicy&uxId=nike.cdm.sportpulse-oauth&mobileStatus=true&country=${store?.country_code}&language=${language}&requestType=redirect`}
        >{t('Privacy Policy')}</a>
      </div>
      <div className='w-fit'>
        <a
          className='border-b border-black text-base h-fit w-min'
          target="_blank"
          rel="noreferrer"
          href={`https://agreementservice.svs.nike.com/rest/agreement?agreementType=termsOfUse&uxId=nike.cdm.sportpulse-oauth&mobileStatus=true&country=${store?.country_code}&language=${language}&requestType=redirect`}
        >{t('Terms Of Use')}</a>
      </div>
    </div >
  )
}