import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import Typography from "@components/typography";
import Button from "@components/button";
import {useDeleteSessions} from "@hooks/session";
import {useUser} from "@hooks/user";
import useDecodedToken from "@hooks/token.ts";
import {useMemo} from "react";
import {t} from "i18next";

export default function SessionSummaryChange({
                                               gridSize,
                                               enableReschedule
                                             }: {
  gridSize: number;
  enableReschedule: boolean;
}) {
  const {t} = useTranslation();

  const token = useDecodedToken();
  const canWrite = token?.roles.includes('CUSTOMER_WRITE')

  const height = useMemo(() => {
    if (!canWrite) {
      return 4
    }

    return enableReschedule ? 6 : 5;
  }, [enableReschedule, canWrite, gridSize]);

  return (
    <div className="" style={{height: height * gridSize - 2}}>
      <div className={"mx-3 py-4"}>
        <Typography
          size={24}
          font={"helvetica-now"}
          className={"font-medium text-nearblack"}
        >
          {t('Change of Plans?')}
        </Typography>

        <div className="mt-4 font-medium">
          {
            canWrite ? <SessionChangeButtons enableReschedule={enableReschedule} /> : <CheckEmail />
          }
        </div>
      </div>
    </div>
  );
}

function SessionChangeButtons({enableReschedule}: { enableReschedule: boolean }) {
  const {deleted, handleDelete, deleting} = useDeleteSessions();
  const {user, loading} = useUser();
  const navigate = useNavigate();

  return (
    <>
      {
        user && enableReschedule && (
          <Button
            size={"small"}
            theme="dark"
            variant="outline"
            onClick={() => navigate('/book')}
            disabled={loading || !user}
            className={"mt-4 py-1"}
            track={{
              type: 'reschedule_session_button_clicked'
            }}
          >
            {t('Reschedule Your Session')}
          </Button>
        )}
      <Button
        size={"small"}
        theme="dark"
        disabled={deleting || deleted || loading || !user}
        variant="outline"
        onClick={() => handleDelete(user)}
        className={"mt-4 py-1"}
        track={{
          type: 'cancel_session_button_clicked'
        }}
      >
        {deleting ? t("Deleting...") : t("Cancel Your Session")}
      </Button>
    </>
  )
}

function CheckEmail() {
  return (
    <div className={'text-md font-medium leading-tight'}>
      {t('Please check your email to modify or cancel your session.')}
    </div>
  )
}