import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { DESKTOP_PHONE_WIDTH, GRID_HEIGHT_FACTOR } from "../constants";
import { useIsDesktop } from "@hooks/use-is-desktop";

export default function BackgroundGrid({
  fit = 6,
  introAnimate = false,
  asPortal = true,
}: {
  fit?: number;
  introAnimate?: boolean;
  asPortal?: boolean;
}) {
  const [_, setAnimate] = useState(introAnimate);
  const isDesktop = useIsDesktop();
  const { size, count } = useMemo(() => {
    return {
      size:
        (isDesktop ? DESKTOP_PHONE_WIDTH : window.innerWidth) / fit,
      //   INCREASE GRID_HEIGHT_FACTOR IN CONSTANTS.JS IF YOU NEED MORE VERTICAL SPACE IN THE SESSION MANAGER
      count: fit * GRID_HEIGHT_FACTOR,
    };
  }, [fit, isDesktop]);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(false);
    }, 4000);
  }, []);

  const grid = (
    <div className={"absolute h-full w-full overflow-hidden"}>
      <div
        className={"fixed left-0 top-0 -z-[1] flex h-full flex-wrap opacity-10"}
      >
        {Array.from({ length: count }).map((_, i) => (
          <div
            key={i}
            style={{ width: size, height: size }}
            className={"border-b-2 border-r-2 border-black"}
          />
        ))}
      </div>
    </div>
  );

  return asPortal ? createPortal(grid, document.body) : grid;
}
