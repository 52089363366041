import { Trans, useTranslation } from "react-i18next";

import { useTextOptIn } from "@hooks/session";
import { useUser } from "@hooks/user";
import { DEFAULT_EASE } from "@lib/shared";

import Modal from "./modal";
import { motion } from 'framer-motion';
import Typography from "./typography";
import Button from "./button";
import { useContext, useState } from "react";
import { usePublicQuery } from "@hooks/apollo";
import { STORE } from "../data/gql/queries/store";
import { AppContext } from "../context/app";

interface OptInModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function OptInModal({ isOpen, onClose }: OptInModalProps) {
  const { user } = useUser();
  const { t } = useTranslation();
  const optIn = useTextOptIn();
  const nextSession = user?.next_session || user?.last_session;
  const [loading, setLoading] = useState(false);
  const { storeId, language } = useContext(AppContext);
  const { data } = usePublicQuery(STORE, {
    variables: {
      objectId: storeId
    }
  });

  const handleOptIn = async () => {
    setLoading(true);
    await optIn(nextSession?.id, true).then(() => onClose());
    setLoading(false);
  }

  const handleOptOut = async () => {
    setLoading(true);
    await optIn(nextSession?.id, false).then(() => onClose());
    setLoading(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      showClose={false}
      blurBg={false}
    >
      <motion.div
        key='opt_in_modal'
        className="flex items-center justify-center w-full h-full"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          ease: DEFAULT_EASE,
          duration: 0.4,
          delay: 0,
        }}
      >
        <div className='w-desktop-phone' style={{ padding: `30px 24px` }}>
          <div className="flex justify-between items-center grow gap-3">
            <TextIcon />
            <div>
              <Typography className='font-medium w-fit' size={24}>
                {t('Get Text Notifications')}
              </Typography>
              <Typography className='font-normal max-w-[497px]' size={16}>
                {t('By pressing “Opt-In”, I consent to receive a text message from Nike in connection with the Nike By You to receive up to 3 texts in order to receive appointment updates.')}
              </Typography>
            </div>
          </div>
          <div className={"flex w-full gap-2 my-[40px]"}>
            <Button
              onClick={handleOptIn}
              track={{
                type: 'opt_in_button_clicked'
              }}
              size='medium'
              fullWidth
              disabled={loading}
            >
              {t('Opt-In')}
            </Button>
            <Button
              track={{
                type: 'opt_out_button_clicked'
              }}
              onClick={handleOptOut}
              variant="outline"
              theme="dark"
              size='medium'
              fullWidth
              className="border-2 bg-transparent border-black"
              disabled={loading}
            >
              {t('No Thanks')}
            </Button>
          </div>
          <Typography size={12} style={{ whiteSpace: 'pre-wrap' }}>
            <Trans i18nKey="smsLegalCopy">
              Nike will send you Nike By You SMS approximately 3 notification messages until your booked session has been completed. Messages are automatically dialed. No purchase necessary. For help with SMS booking updates call <a href="tel:+33134301300">+33 1 34 30 13 00</a>. To stop Nike By You messages reply STOP. Message & Data Rates May Apply.
            </Trans>
          </Typography>
          <div className="flex gap-2 mt-5">
            <a
              className='border-b border-black text-xs'
              target="_blank"
              rel="noreferrer"
              href={`https://agreementservice.svs.nike.com/rest/agreement?agreementType=privacyPolicy&uxId=nike.cdm.sportpulse-oauth&mobileStatus=true&country=${data?.store?.country_code}&language=${language}&requestType=redirect`}
            >{t('Privacy Policy')}</a>
            <a
              className='border-b border-black text-xs'
              target="_blank"
              rel="noreferrer"
              href={`https://agreementservice.svs.nike.com/rest/agreement?agreementType=termsOfUse&uxId=nike.cdm.sportpulse-oauth&mobileStatus=true&country=${data?.store?.country_code}&language=${language}&requestType=redirect`}
            >{t('Terms Of Use')}</a>
          </div>
        </div>
      </motion.div>
    </Modal>
  )
}

function TextIcon() {
  return (
    <svg width="93" height="102" viewBox="0 0 93 102" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M58.8935 39.8398L85.1973 65.6655C86.6527 66.9779 87.8305 68.5681 88.6614 70.3424C89.4922 72.1167 89.9594 74.0392 90.0353 75.9967L90.0353 99.576M55.2989 99.9998L39.803 76.6155C39.2519 75.8044 38.8688 74.8913 38.6762 73.93C38.4837 72.9686 38.4856 71.9785 38.6818 71.018C38.8781 70.0574 39.2646 69.1458 39.8188 68.3368C40.3731 67.5278 41.0837 66.8377 41.9089 66.3074C42.734 65.777 43.657 65.417 44.6235 65.2486C45.59 65.0802 46.5805 65.1067 47.5366 65.3267C48.4927 65.5466 49.3951 65.9555 50.1906 66.5293C50.9862 67.1031 51.6588 67.8302 52.1688 68.6677L62.4978 84.7132" stroke="#111111" stroke-width="4" />
      <path d="M2 99.5764L2 14.1012C2 7.42136 7.42526 2 14.11 2L46.4032 2C53.0879 2 58.5131 7.42136 58.5131 14.1012L58.5131 50.9343L58.5131 78.4907" stroke="#111111" stroke-width="4" />
      <path d="M38.6647 50.375H42.9981C45.6891 50.375 47.8731 48.1932 47.8731 45.5V26C47.8731 23.3068 45.6891 21.125 42.9981 21.125H16.998C14.307 21.125 12.123 23.3068 12.123 26V45.5C12.123 48.1932 14.307 50.375 16.998 50.375H29.998C32.689 50.375 34.8731 52.5568 34.8731 55.25V58.5M36.4981 40.625H20.248M39.7481 30.875H20.248" stroke="#111111" stroke-width="4" />
    </svg>
  )
}