import { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import GridBox from "@components/grid-box";
import GridBoxStatusSection from "@components/grid-box-status-section";

import SessionSummaryDetails from "./session-summary-details";
import SessionSummaryPrep from "./session-summary-prep";
import SessionSummaryDesign from "./session-summary-design";
import SessionSummaryChange from "./session-summary-change";
import SessionSummaryShare from "./session-summary-share";
import SessionSummarySupport from "./session-summary-support";

import { BOOKING_TYPES, SESSION_STATUS_TYPES, Session, Store } from "../../../data/types";
import { DESKTOP_PHONE_WIDTH, GRID_FIT } from "../../../constants";
import { getFirstDesign } from "@lib/session";
import { useIsDesktop } from "@hooks/use-is-desktop";
import OptInModal from "@components/opt-in-modal";
import SessionSummaryLegal from "./session-summary-legal";
import { useExperienceType } from "@hooks/experience.tsx";
import SessionSummaryRefreshLogo from "./session-summary-refresh-logo.tsx";

interface SessionSummaryProps {
  session: Session;
  store: Store;
}

export default function SessionSummary({ session, store }: SessionSummaryProps) {
  const { t } = useTranslation();
  const design = getFirstDesign(session);
  const status = session.latest_status;
  const [optInModalOpen, setOptInModalOpen] = useState(
    session?.text_notifications_allowed === null && session?.has_contact_number
  );
  const isDesktopPhonePage = useIsDesktop();
  const [gridSize, setGridSize] = useState(
    (isDesktopPhonePage ? DESKTOP_PHONE_WIDTH : window.innerWidth) / GRID_FIT
  );

  const title = useMemo(() => {
    if ([
      SESSION_STATUS_TYPES.PROCESSING
    ].includes(status)) {
      return t("Order Confirmed");
    }

    if (status === SESSION_STATUS_TYPES.PICKUP) {
      return t("Ready for Pick Up!");
    }

    if (status === SESSION_STATUS_TYPES.COMPLETED) {
      return t("Order Completed!");
    }

    return t("Your Upcoming Session");
  }, [status, design, t]);

  const afterSession = useMemo(() => {
    return status
      ? [
        SESSION_STATUS_TYPES.NO_SHOW,
        SESSION_STATUS_TYPES.PROCESSING,
        SESSION_STATUS_TYPES.PICKUP,
        SESSION_STATUS_TYPES.COMPLETED,
      ].includes(status)
      : false;
  }, [status]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const inProgress = [
    SESSION_STATUS_TYPES.IN_PROGRESS,
  ].includes(status);

  return (
    <div key={session.id}>
      <GridBoxStatusSection gridSize={gridSize} title={title} />

      {design && (
        <GridBox onGridSize={setGridSize}>
          <SessionSummaryDesign
            status={status}
            showDetails={status !== "mobile_notified_check_in"}
            session={session}
            gridSize={gridSize}
            store={store}
          />
        </GridBox>
      )}

      {!afterSession ? (
        <PreSession
          onGridSize={setGridSize}
          gridSize={gridSize}
          session={session}
          store={store}
          inProgress={inProgress}
        />
      ) : (
        <AfterSession
          gridSize={gridSize}
          status={status}
        />
      )}

      <OptInModal
        isOpen={optInModalOpen}
        onClose={() => {
          setOptInModalOpen(false);
        }}
      />
    </div>
  );
}

interface PreSessionProps {
  onGridSize: (value: (((prevState: number) => number) | number)) => void;
  gridSize: number;
  session: Session;
  store: Store;
  inProgress: boolean;
}

function PreSession({ gridSize, inProgress, onGridSize, session, store }: PreSessionProps) {
  const design = getFirstDesign(session);
  const experienceType = useExperienceType();

  return <>
    {
      experienceType !== 'clean_care' && (
        <GridBox onGridSize={onGridSize}>
          <SessionSummaryPrep
            gridSize={gridSize}
            design={design || undefined}
          />
        </GridBox>
      )
    }

    <GridBox onGridSize={onGridSize}>
      <SessionSummaryDetails
        session={session}
        gridSize={gridSize}
        store={store}
      />
    </GridBox>

    {!inProgress && <GridBox onGridSize={onGridSize}>
      <SessionSummaryChange
        gridSize={gridSize}
        enableReschedule={session.booking?.type === BOOKING_TYPES.RESERVED}
      />
    </GridBox>}

    <GridBox onGridSize={onGridSize}>
      <SessionSummaryLegal
        gridSize={gridSize}
        store={store}
      />
    </GridBox>
  </>;
}

function AfterSession(props: { gridSize: number, status: string }) {
  return <>
    <GridBox>
      <SessionSummaryShare gridSize={props.gridSize} />
    </GridBox>

    <GridBox>
      <SessionSummarySupport
        gridSize={props.gridSize}
        showSatisfied={props.status === "completed"}
      />
    </GridBox>
  </>;
}

