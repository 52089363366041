import {motion} from "framer-motion";
import {DEFAULT_EASE} from "@lib/shared.ts";
import Typography from "@components/typography.tsx";
import Button from "@components/button.tsx";
import Modal from "@components/modal.tsx";
import {ReactNode} from "react";
import {t} from "i18next";

interface ConfirmModalProps {
  title: string;
  children: ReactNode;
  isOpen: boolean;
  onAccept: () => void;
  confirm?: string;
}

export default function ConfirmModal({title, children, isOpen, onAccept, confirm = t('Accept')}: ConfirmModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onAccept}
      showClose={false}
      blurBg={false}
    >
      <motion.div
        key='opt_in_modal'
        className="flex items-center justify-center w-full h-full"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          ease: DEFAULT_EASE,
          duration: 0.4,
          delay: 0,
        }}
      >
        <div className='w-desktop-phone' style={{padding: `30px 24px`}}>
          <Typography className='font-medium w-fit' size={24}>
            {title}
          </Typography>
          <Typography className={'mt-3 mb-4'} size={16}>
            {children}
          </Typography>
          <Button
            onClick={onAccept}
            size='small'
            fullWidth={false}
          >
            {confirm}
          </Button>
        </div>
      </motion.div>
    </Modal>
  )
}