import {motion, useScroll} from 'framer-motion';
import Modal from "./modal";
import {DEFAULT_EASE} from '@lib/shared';
import {useTranslation} from 'react-i18next';
import Button from './button';
import {ReactNode, useEffect, useMemo, useRef, useState} from "react";
import Typography from "@components/typography.tsx";
import {useExperience} from "@hooks/experience.tsx";
import {useLanguage} from "@hooks/language.ts";
import Markdown from "react-markdown";
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw'

export default function TermsModal({isOpen, onAccept}: { isOpen: boolean, onAccept: () => void }) {
  const [content, setContent] = useState<string>('');
  const experience = useExperience();
  const {t} = useTranslation();
  const language = useLanguage();
  const [scrollCompleted, setScrollCompleted] = useState(false);

  const termsMarkDown = useMemo(() => {
    const FALLBACK_LANG = 'en';
    const terms = experience?.terms_and_conditions;
    const foundTerms = terms?.urls.find((term) => term.lang === language);

    return foundTerms || terms?.urls.find((term) => term.lang === FALLBACK_LANG);
  }, [experience, language]);

  useEffect(() => {
    if (termsMarkDown) {
      fetch(termsMarkDown.url)
        .then((response) => response.text())
        .then((data) => {
          setContent(data);
        });
    }
  }, [termsMarkDown]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onAccept}
      showClose={false}
      blurBg={false}
      className={'z-40'}
    >
      <TermsContainer>
        <div className={'flex-grow flex flex-col pb-8'}>
          <Typography className={"font-medium text-nearblack"} size={24}>
            {t('Terms & Conditions')}
          </Typography>

          {
            content?.length > 0 ? (
              <ScrollToComplete
                content={content}
                onScrollComplete={() => {
                  setScrollCompleted(true);
                }}
              />
            ) : <div />
          }

        </div>

        <div className={'flex-shrink-0'}>
          <Button
            onClick={onAccept}
            fullWidth={true}
            disabled={!scrollCompleted}
          >
            {scrollCompleted ? t('Continue') : t('Scroll to Continue')}
          </Button>
        </div>
      </TermsContainer>
    </Modal>
  )
}

interface ScrollToCompleteProps {
  onScrollComplete: () => void;
  content: string;
}

function ScrollToComplete({onScrollComplete, content}: ScrollToCompleteProps) {
  const container = useRef<HTMLDivElement>(null);
  const {scrollYProgress} = useScroll({
    container,
  });

  useEffect(() => {
    scrollYProgress.on('change', (latest) => {
      if (latest > 0.95) {
        onScrollComplete();
      }
    });
  }, []);

  return (
    <div className={'border border-neutral-300 rounded-lg mt-4'}>
      <motion.div
        ref={container}
        className="mr-2 overflow-y-scroll max-h-[65vh]"
      >
        <div className={'p-4 pb-16 prose prose-sm prose-neutral'}>
          <Markdown
						disallowedElements={['h1']}
						remarkPlugins={[gfm]}
						rehypePlugins={[rehypeRaw]}
					>
            {content}
          </Markdown>
        </div>
      </motion.div>
    </div>
  )
}

function TermsContainer({children}: { children: ReactNode }) {
  return (
    <motion.div
      key='opt_in_modal'
      className="flex flex-col z-[60] justify-between w-full max-h-screen h-screen bg-white px-4 pb-32 pt-6"
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        ease: DEFAULT_EASE,
        duration: 0.4,
        delay: 0,
      }}
    >
      {children}
    </motion.div>
  )
}
