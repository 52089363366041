import { useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { PUBLIC_GET_CUSTOMER_TOKENS, GET_USER } from "../data/gql/queries/user";
import { useLazyQuery } from "@apollo/client";
import { useAuth } from "oidc-react";
import { usePublicLazyQuery } from "./apollo";
import { useEndAnalyticsSession } from "./analytics";
import { filterSessionsByStoreExperience } from "@lib/session";
import { AppContext } from "../context/app";
import { useSessionType } from "./experience";
import useDecodedToken from "./token";

export const useUser = () => {
  const navigate = useNavigate();
  const [tokenStr, setTokenStr] = useState(window.localStorage.getItem('tokens'));
  const [attempts, setAttempts] = useState(0);
  const sessionType = useSessionType();
  const [getUser, { data, loading }] = useLazyQuery(GET_USER, {
    variables: {
      sessionType
    }
  });
  const [getCustomerTokens, {
    loading: tokensLoading
  }] = usePublicLazyQuery(PUBLIC_GET_CUSTOMER_TOKENS);
  const { signIn, isLoading, signOut } = useAuth();
  const endAnalyticsSession = useEndAnalyticsSession();
  const { storeId, experienceId } = useContext(AppContext);
  const token = useDecodedToken();

  const logout = (path = '/') => {
    window.localStorage.removeItem('tokens');
    setTokenStr('');
    signOut();
    endAnalyticsSession();
    navigate(path);
  }

  const fetchUser = useCallback(async () => {
    if (!tokenStr || loading) return;

    return getUser();
  }, [getUser, loading, tokenStr, logout]);

  const signInFromNike = useCallback(async (token: string) => {
    if (!token) return;
    const { data, error } = await getCustomerTokens({
      variables: {
        nikeAccessToken: token
      }
    });

    if (error) {
      return logout();
    }
    const tokens = JSON.stringify(data?.getCustomerTokens);
    localStorage.setItem('tokens', tokens);
    setTokenStr(tokens);

    await fetchUser();
  }, [getCustomerTokens, logout, fetchUser]);

  useEffect(() => {
    if (tokenStr && !data && !loading) {
      fetchUser()
        .then(() => setAttempts((a) => a + 1))
        .catch((err) => {
          if (err.status === 401) return;
          return setAttempts((a) => a + 1);
        });
    }
  }, [tokenStr, fetchUser, loading, data]);

  useEffect(() => {
    if (attempts > 3 && !data) logout();
  }, [attempts, data, loading, logout]);

  const user = useMemo(() => {
    return data?.whoAmI ? {
      nu_id: data?.whoAmI?.nu_id,
      first_name: data?.whoAmI?.first_name,
      g_preferred_name: data?.whoAmI?.g_preferred_name,
      g_id: data?.whoAmI?.g_id,
      is_guest: !!data?.whoAmI?.g_id,
      email: token?.g_email,
      next_session: filterSessionsByStoreExperience(
        (data?.whoAmI?.queued_sessions || []).concat(data?.whoAmI?.future_sessions || []),
        storeId,
        experienceId
      )[0],
      last_session: filterSessionsByStoreExperience(
        data?.whoAmI?.past_sessions,
        storeId,
        experienceId
      )[0],
    } : null
  }, [data?.whoAmI, storeId, experienceId]);

  return {
    user,
    loading: loading || (tokenStr && !data?.whoAmI) || isLoading || tokensLoading,
    logout,
    login: signIn,
    signInFromNike,
    refetch: fetchUser
  };
};