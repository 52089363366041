import { VITE_NBY_API_BASE_URL, VITE_STAGE } from "../constants";

export const refreshTokens = async (refresh_token: string = "") => {
  try {
    const url = `${VITE_NBY_API_BASE_URL}/auth/refresh`;
    const body = JSON.stringify({ refresh_token })
    const response = await fetch(url, {
      method: "post",
      headers: { 'Content-Type': 'application/json', 'Accept':'application/json' },
      body,
    })
    const data = await response.json()
    return data?.tokens
  } catch (error) {
    if (VITE_STAGE !== 'production') console.log("NBY.refresh ERROR: ", error);
    throw error;
  }
};
