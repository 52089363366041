import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import PageTransition from "@components/page-transition";
import Button from "@components/button";
import Header from "@components/header";
import { usePublicQuery } from "@hooks/apollo";
import { STORE } from "../../data/gql/queries/store";
import { useContext, useEffect, useMemo } from "react";
import { AppContext } from "../../context/app";
import { useExperienceType } from "@hooks/experience";
import { cn } from "@lib/shared.ts";
import { LanguageSelectorButton } from "@components/language-selector.tsx";

const DEFAULT_VIDEO =
  "https://static.nike.com/sportpulse/video/upload/q_auto/v1710193302/nby-reserve/mobile_landing.mp4";

const CLEANING_VIDEO =
  "https://static.nike.com/sportpulse/video/upload/q_auto/v1712167938/clean_care/Refresh_Loop_R3.mp4";

export default function Intro() {
  const experienceType = useExperienceType();

  const videoUrl = useMemo(() => {
    switch (experienceType) {
      case "clean_care":
        return CLEANING_VIDEO;
      default:
        return DEFAULT_VIDEO;
    }
  }, [experienceType]);

  return (
    <PageTransition
      className="z-0 flex h-screen overflow-hidden flex-col justify-end bg-white bg-cover bg-center bg-no-repeat text-black"
      type={{
        in: "fade",
        out: "left",
      }}
    >
      <video
        src={videoUrl}
        autoPlay
        className={cn("absolute left-0 top-0", {
          "object-cover object-top": experienceType === "clean_care",
        })}
        muted
        loop
        playsInline
      />

      {
        experienceType === "clean_care" && (
          <CleanIntroContent />
        )
      }
      {
        experienceType === "nby" && (
          <NbyIntroContent />
        )
      }
    </PageTransition>
  );
}

function NbyIntroContent() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { store, experience } = useContext(AppContext);

  return (
    <>
      <Header className="z-20 mb-14 self-start" title={store?.full_name} />

      <div className={"z-20 mb-6 ml-5 mr-5 flex gap-4"}>
        {experience?.booking_enabled && <Button
          onClick={() => {
            navigate("/book");
          }}
          theme={"light"}
          size={"medium"}
          track={{
            type: 'book_now_button_clicked'
          }}
        >
          {t('Book Now')}
        </Button>}
      </div>
    </>
  )
}

function CleanIntroContent() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { store, experience } = useContext(AppContext);

  return (
    <>
      <div className={'absolute top-20 left-4'}>
        <LanguageSelectorButton />
      </div>
      <div className={'px-5 mb-10 z-20'}>
        <div className=" font-semibold text-2xl leading-6">
          {store?.full_name}
        </div>
        <div className={'mt-1'}>
          {store?.address?.split('\n')?.[0]}
        </div>
      </div>

      <div className={"z-20 mb-6 ml-5 mr-5 flex gap-4"}>
        {experience?.booking_enabled && <Button
          onClick={() => {
            navigate("/book");
          }}
          theme={"light"}
          size={"medium"}
          fullWidth
          track={{
            type: 'book_now_button_clicked'
          }}
        >
          {t('Book Now')}
        </Button>}
      </div>
    </>
  )
}
