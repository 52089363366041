interface TextFieldProps {
  value?: string;
  onChange: (value: string) => void;
  type?: string;
  label: string;
}

export default function TextField({onChange, type = 'text', label}: TextFieldProps) {
  return (
    <div className="w-full my-8">
      <div className="relative w-full min-w-[200px] h-10">
        <input
          className="peer w-full h-full bg-transparent text-neutral-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-neutral-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-neutral-700 placeholder-shown:border-t-neutral-700 border border-t-transparent focus:border-t-transparent px-3 py-6 rounded-[7px] border-neutral-700 focus:border-gray-900"
          placeholder=" "
          type={type}
          // value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        <label
          className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-neutral-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-neutral-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t before:border-l before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t after:border-r after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.5] text-gray-500 peer-focus:text-gray-900 before:border-neutral-700 peer-focus:before:!border-gray-900 after:border-neutral-700 peer-focus:after:!border-gray-900">
          {label}
        </label>
      </div>
    </div>
  )
}