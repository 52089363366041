import {Guest} from "../../data/types.ts";
import {useReserveBooking} from "@hooks/session.tsx";
import {useContext, useMemo, useState} from "react";
import {AppContext} from "../../context/app.tsx";

export function useGuestBooking() {
  const [reserveBooking, {loading: booking, errorType}] = useReserveBooking();
  const {heldTimeSlot} = useContext(AppContext);

  async function handleGuestBooking(guest: Guest) {
    if (!heldTimeSlot) {
      throw new Error("No time slot held");
      return;
    }

    return await reserveBooking({timeslot: heldTimeSlot, guest});
  }

  return {handleGuestBooking, alreadyHasSession: errorType === 'already_has_session'};
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function useGuestBookForm() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const isReady = useMemo(() => {
    const emailValid = emailRegex.test(email);
    const nameValid = name.length >= 1;

    return emailValid && nameValid && privacyChecked;
  }, [email, name, privacyChecked]);

  return {
    email, setEmail, name, setName, privacyChecked, setPrivacyChecked, isReady
  }
}