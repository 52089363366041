import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Typography from "@components/typography";
import { isLocalDev } from "@lib/shared";
import { useUser } from "@hooks/user";

export default function SignIn() {
  const navigate = useNavigate();
  const { signInFromNike, login } = useUser();

  const onPaste = () => {
    navigator.clipboard.readText().then(async (token) => {
      if (!token) return;
      await signInFromNike(token);
      navigate('/confirm');
    });
  }

  useEffect(() => {
    if (isLocalDev) {
      document.addEventListener("paste", onPaste, false);
    } else {
      login();
    }
  });

  return isLocalDev ? (
    <div className={"mx-4 mb-8 pt-6"}>
      <Typography
        className={"font-medium text-nearblack"}
        size={24}
        lineHeight={"28px"}
      >
        Nike Login
      </Typography>
      <Typography className={"mt-2 text-neutral-500"} size={24}>
        Paste your nike accounts access token from Postman
      </Typography>
    </div>
  ) : null;
}
