import _ from "lodash";
import { useMutation } from "@apollo/client";
import { ADD_DESIGNS, UPDATE_DESIGN } from "../data/gql/mutations/design";
import { GET_USER } from "../data/gql/queries/user";
import { useSessionType } from "./experience";

interface DesignInput {
  product_size_id?: string;
  product_color_id?: string;
  product_id?: string;
  inspiration_ids?: Array<string>;
}

export function useSaveDesign() {
  const sessionType = useSessionType();
  const [addDesigns, { loading: designSaving }] = useMutation(ADD_DESIGNS, {
    refetchQueries: [
      {
        query: GET_USER,
        variables: { sessionType },
      },
    ],
  });

  const saveDesign = async (sessionId: string, object: DesignInput) => {
    if (designSaving) return;
    return addDesigns({
      variables: {
        objects: [
          {
            session_id: sessionId,
            ...object,
          },
        ],
      },
    });
  };

  return { saveDesign, designSaving };
}

export function useUpdateDesign() {
  const sessionType = useSessionType();
  const [updateDesign, { loading: designUpdating }] = useMutation(UPDATE_DESIGN, {
    refetchQueries: [
      {
        query: GET_USER,
        variables: { sessionType },
      },
    ],
  });

  const saveDesign = async (objectId: string, object: DesignInput) => {
    if (designUpdating) return;
    return updateDesign({
      variables: {
        objectId,
        object,
      },
    });
  };
  return { updateDesign: saveDesign, designUpdating };
}
