import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
  // useSearchParams,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import jwtDecode from "jwt-decode";
import "react-confirm-alert/src/react-confirm-alert.css";

import NotFound from "./pages/not-found";

import ScrollToTop from "./components/scroll-to-top";
import { useIsDesktop } from "./hooks/use-is-desktop";
import Start from "./pages/start/start";
import Intro from "./pages/intro/intro";
import Book from "./pages/book/book";
import SignIn from "./pages/sign-in/sign-in";
import SessionManager from "./pages/session-manager/session-manager";
import PreDesign from "./pages/pre-design/pre-design";
import { useContext, useEffect } from "react";
import { AuthProvider } from "oidc-react";
import { NBY_TOKEN_TYPE, VITE_OIDC_CLIENT_ID, VITE_OIDC_URL, VITE_STAGE } from "./constants";
import { GET_USER_TOKENS, PUBLIC_GET_CUSTOMER_TOKENS } from "./data/gql/queries/user";
import { usePublicLazyQuery } from "@hooks/apollo";
import Loading from "@components/loading";
import ConfirmBooking from "./pages/confirm-booking/confirm-booking";
import Confirmed from "./pages/confirmed/confirmed";
import { useAnalytics, useStartAnalyticsSession } from "@hooks/analytics";
import { useLazyQuery } from "@apollo/client";
import { AppContext } from "./context/app";
import CookieModal from "@components/cookie-modal";
import CleaningPricing from "./pages/cleaning-pricing/cleaning-pricing";
import GuestSignIn from "./pages/guest-sign-in/guest-sign-in";
import { useSessionType } from "@hooks/experience";

export default function App() {
  const location = useLocation();
  const isDesktop = useIsDesktop();
  const [getPublicCustomerTokens] = usePublicLazyQuery(PUBLIC_GET_CUSTOMER_TOKENS);
  const [getUserTokens] = useLazyQuery(GET_USER_TOKENS);
  const [searchParams, setSearchParams] = useSearchParams();
  const accessToken = searchParams.get("access_token");
  const clearTokens = searchParams.get("clear_tokens") === 'true';
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const startAnalyticsSession = useStartAnalyticsSession();
  const sessionType = useSessionType();
  const { cookiesAccepted, acceptCookies } = useContext(AppContext);

  const wrapperClasses = isDesktop
    ? "w-screen h-screen flex flex-row justify-center items-center bg-desktop-phone-grey"
    : "";
  const appClasses = isDesktop
    ? `h-full w-desktop-phone overflow-scroll bg-white`
    : "h-px min-h-screen w-screen";

  useEffect(() => {
    analytics.handleUnload();
    startAnalyticsSession();
  }, []);

  useEffect(() => {
    analytics.transitionTo(location.pathname);
  }, [location.pathname]);

  const signInFromParams = async (accessToken: string) => {
    if (accessToken) {
      const decodedAccess = jwtDecode(accessToken) as any;
      const expiresAccessMs = (decodedAccess?.exp || 0) * 1000;
      const accessTokenType = decodedAccess?.token_type || null;
      const dateNowMs = Date.now();

      if (!expiresAccessMs || dateNowMs > expiresAccessMs || accessTokenType !== NBY_TOKEN_TYPE) {
        return localStorage.removeItem('tokens');
      }

      localStorage.setItem('tokens', JSON.stringify({
        access: {
          token: accessToken,
          expires: expiresAccessMs,
        },
      }));

      const { data } = await getUserTokens({
        variables: {
          sessionType
        }
      });

      if (!data?.whoAmI?.tokens) {
        localStorage.removeItem('tokens');
        return navigate('/');
      }

      const tokens = JSON.stringify(data?.whoAmI?.tokens);
      localStorage.setItem('tokens', tokens);
    }
  };

  useEffect(() => {
    if (accessToken) {
      signInFromParams(accessToken);
      searchParams.delete("access_token");
      setSearchParams(searchParams);
      navigate('/confirm');
    }
    if (clearTokens) {
      window.localStorage.removeItem('tokens');
      searchParams.delete("clear_tokens");
    }
  });

  const signInFromNike = async (token?: string) => {
    if (!token) return;
    try {
      const { data, error } = await getPublicCustomerTokens({
        variables: {
          nikeAccessToken: token
        }
      });

      if (error) throw Error(error.message);
      const tokens = JSON.stringify(data?.getCustomerTokens);
      localStorage.setItem('tokens', tokens);
      navigate('/confirm');
    } catch (err) {
      if (VITE_STAGE !== 'production') console.log('fetch tokens err', err)
      localStorage.removeItem('tokens');
    }
  };

  return (
    <AuthProvider
      authority={VITE_OIDC_URL}
      clientId={VITE_OIDC_CLIENT_ID}
      redirectUri={`${window.location.origin}/callback`}
      autoSignIn={false}
      onSignIn={(userData) => signInFromNike(userData?.access_token)}
    >
      <div className={wrapperClasses}>
        <div className={appClasses}>
          <Routes location={location} key={location.pathname}>
            <Route path={"/"} element={<Start />} />
            <Route path={"/intro"} element={<Intro />} />
            <Route path={"/book"} element={<Book />} />
            <Route path={"/sign-in"} element={<SignIn />} />
            <Route path={"/callback"} element={<Loading />} />
            <Route path={"/cleaning-pricing"} element={<CleaningPricing />} />
            <Route path={"/guest-sign-in"} element={<GuestSignIn />} />
            <Route path={"/confirm"} element={<ConfirmBooking />} />
            <Route path={"/confirmed"} element={<Confirmed />} />
            <Route path={"/session-manager"} element={<SessionManager />} />
            <Route path={"/pre-design"} element={<PreDesign />} />
            <Route path={"/pre-design/:designId"} element={<PreDesign />} />
            <Route path="*" element={<NotFound />} />
          </Routes>

          <ToastContainer
            position="bottom-center"
            autoClose={2000}
            newestOnTop={true}
            closeOnClick={true}
            rtl={false}
            pauseOnHover
            theme="colored"
          />
          <ScrollToTop />
        </div>
      </div>
      <CookieModal
        isOpen={!cookiesAccepted}
        onAccept={acceptCookies}
      />
    </AuthProvider>
  );
}
