import { CSSProperties, ReactNode, useMemo } from "react";
import { cleanObject } from "../lib/shared";

export type fonts = "futura" | "palatino" | "helvetica-neue" | "helvetica-now";

interface TypographyProps {
  font?: fonts;
  style?: CSSProperties;
  className?: ReactNode;
  children: ReactNode;
  size?: number;
  lineHeight?: number | string;
  letterSpacing?: number | string;
}

export default function Typography({
  font = "helvetica-now",
  children,
  className,
  style,
  size = undefined,
  lineHeight = undefined,
  letterSpacing = undefined
}: TypographyProps) {
  const selectedFont = useMemo(() => {
    const all: Record<fonts, string> = {
      futura: "font-futura uppercase",
      palatino: "font-palatino italic antialiased",
      "helvetica-neue": "font-neue",
      "helvetica-now": "font-now",
    };
    return all?.[font];
  }, [font]);

  return (
    <div
      className={`${selectedFont}${className ? ` ${className}` : ""}`}
      style={{
        ...style,
        ...cleanObject({
          fontSize: size,
          lineHeight: lineHeight,
          letterSpacing
        }),
      }}
    >
      {children}
    </div>
  );
}
