import { useIsDesktop } from "@hooks/use-is-desktop";
import { ReactNode } from "react";
import { DESKTOP_PHONE_WIDTH } from "../constants";

export default function GridBox({
  horizontalBoxCount = 11,
  children,
  className = "",
}: {
  horizontalBoxCount?: number;
  children: ReactNode;
  onGridSize?: (gridSize: number) => void;
  forceSize?: number;
  className?: string;
}) {
  const isDesktopPhonePage = useIsDesktop();
  const padding =
    (isDesktopPhonePage ? DESKTOP_PHONE_WIDTH : window.innerWidth) /
    horizontalBoxCount;

  return (
    <>
      <div
        style={{
          margin: `${padding - 2}px ${padding}px -4px ${padding - 2}px`,
        }}
        className={`border-2 border-opacity-10 bg-white ${className}`}
      >
        <div>{children}</div>
      </div>
      <div style={{ height: "4px" }}></div>
    </>
  );
}
