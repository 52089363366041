import Typography from "@components/typography";
import { ExperienceProductSize } from "../../../data/types";
import { useAnalytics } from "@hooks/analytics";

export default function ProductSizeSelector({
  sizes,
  sizeSelected,
  handleSelectSize = () => null,
}: {
  sizes?: Array<ExperienceProductSize>;
  sizeSelected?: ExperienceProductSize;
  handleSelectSize: (value: ExperienceProductSize) => void;
}) {
  return (
    <div className="mt-5 flex flex-1 flex-col justify-between overflow-scroll">
      <div>
        {!!sizes?.length && (
          <SizeSelector
            sizes={sizes}
            onSelected={(size) => {
              handleSelectSize(size);
            }}
            selectedId={sizeSelected?.id}
          />
        )}
      </div>
    </div>
  );
}

function SizeSelector({
  sizes,
  selectedId,
  onSelected,
}: {
  sizes?: Array<ExperienceProductSize>;
  selectedId?: string;
  onSelected: (size: ExperienceProductSize) => void;
}) {
  const analytics = useAnalytics();

  const handleSelect = (size: ExperienceProductSize) => {
    analytics.setEvent({
      type: "size_selector",
      value: size?.id,
      action: 'selected'
    });
    onSelected(size);
  }

  return (
    <div className="grid grid-cols-3 grid-rows-2 gap-2 px-5">
      {sizes?.map((size: ExperienceProductSize) => (
        <button
          key={`size-${size.id}`}
          className={`flex flex-col items-center justify-center rounded-lg border-2 p-2 py-5 transition-colors
            ${selectedId === size.id ? "border-black" : "border-neutral-300"}`}
          onClick={() => handleSelect(size)}
        >
          <Typography size={16} className={"font-normal"}>
            {size.name}
          </Typography>
        </button>
      ))}
    </div>
  );
}
