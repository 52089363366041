import { useContext } from "react";
import { AnalyticsContext } from "../context/analytics";
import { AppContext } from "../context/app";
import { VITE_STAGE } from "../constants";

export function useStartAnalyticsSession() {
  const { storeId, experienceId, language } = useContext(AppContext);
  const analytics = useAnalytics();

  return () => {
    analytics.startSession();
    analytics.setAttributes({
      "environment": VITE_STAGE,
      "store_id": storeId,
      "experience_id": experienceId,
      "language": language,
      "type": "NBY_CONSUMER_MOBILE_EXPERIENCE"
    });
  }
}

export function useEndAnalyticsSession() {
  const { storeId, experienceId, language } = useContext(AppContext);
  const analytics = useAnalytics();

  return () => {
    // make sure these attributes are set
    analytics.setAttributes({
      "environment": VITE_STAGE,
      "store_id": storeId,
      "experience_id": experienceId,
      "language": language,
      "type": "NBY_CONSUMER_MOBILE_EXPERIENCE"
    });
    // do we want to capture anything else here?
    analytics.endSession();
  }
}

export function useAnalytics() {
  return useContext(AnalyticsContext);
}