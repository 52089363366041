import { gql } from "@apollo/client";

export const STORE = gql`
query Store($objectId: ID!) {
  store(objectId: $objectId) {
    timezone
    slug
    name
    full_name
    address
    locale
    id
    experiences {
      active
      id
      type
      booking_enabled
      inspiration_enabled
      supported_langs
      terms_and_conditions {
        id
        urls {
          lang
          url
        }
      }
			service_items {
				id
				titles {
					copy
					lang
				}
				descriptions {
					copy
					lang
				}
				discounts {
					type
					discount_amount
					currency
				}
				tags
				prices {
					currency
					amount
					price_type
					icon
					source
					effective_date
					modification_date
					authored_date
					updated_date
				}
			}
      products {
        colors {
          hex
          id
          image_url
          name
          retail_availability {
            total_quantity
            availability_by_size {
              size
              availability
              gtin
              quantity
            }
          }
        }
        currency
        id
        image_url
        name
        sizes {
          id
          name
        }
        starting_price_translations {
          lang
          copy
        }
        style_code
        type
        inspirations {
          id
          image_url
        }
      }
    }
    country_code
    locale
  }
}
`;

export const AVAILABLE_DAY_LIST = gql`
query AvailableDayList($storeId: ID!, $experienceId: ID!, $startDatestring: String!, $endDatestring: String!, $sessionType: SessionType!) {
  availableDayList(store_id: $storeId, experience_id: $experienceId, start_datestring: $startDatestring, end_datestring: $endDatestring, session_type: $sessionType) {
    objects {
      bookings_available
      datestring
    }
  }
}
`;
