import AppleWalletButton from "@components/apple-wallet-button";
import Typography from "@components/typography";
import { simpleAmPmTimeFormat, simpleDateFormat } from "@lib/datetime";

import { SESSION_TYPES, Session, Store } from "../../../data/types";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import GoogleWalletButton from "@components/google-wallet-button";
import { useContext, useMemo } from "react";
import { AppContext } from "../../../context/app";
import SessionSummaryRefreshLogo from "./session-summary-refresh-logo";
import { useExperienceType, useSessionType } from "@hooks/experience";
import useDecodedToken from "@hooks/token";

const calculateEstimatedTime = (session: Session) => {
  const now = DateTime.now();
  const sessionStart = DateTime.fromMillis(session.created_date);
  const diffInMinutes = now.diff(sessionStart, 'minutes').toObject().minutes;
  const remainingMins = session.wait_time_at_session_upsert - (diffInMinutes || 0);

  return now.plus({ minutes: remainingMins }).toMillis();
}

export default function SessionSummaryDetails({
  gridSize,
  session,
  store
}: {
  gridSize: number;
  session: Session;
  store: Store;
}) {
  const { language } = useContext(AppContext);
  const experienceType = useExperienceType();
  const bookingStartDate = session?.booking?.start_date || 0;
  const token = useDecodedToken();
  const isWrite = (token?.roles || []).includes('CUSTOMER_WRITE');
  const sessionType = useSessionType();

  const isMobile = useMemo(() => {
    // return true if in dev mode
    if (process.env.NODE_ENV === "local") {
      return true;
    }
    return navigator.userAgent.match(/(iPhone|iPod|iPad)/i)
      || navigator.userAgent.match(/(windows phone)/i)
      || navigator.userAgent.match(/(android)/i);
  }, []);

  return (
    <>
      <div
        className={"flex"}
        style={{
          height: 2 * gridSize - 2,
        }}
      >
        <Date
          gridSize={gridSize}
          bookingStartDate={bookingStartDate}
          timezone={store?.timezone}
          locale={language}
        />
        <Day
          bookingStartDate={bookingStartDate || calculateEstimatedTime(session)}
          timezone={store?.timezone}
          locale={language}
        />
      </div>

      {
        experienceType === 'clean_care' && (
          <SessionSummaryRefreshLogo gridSize={gridSize} />
        )
      }

      <div
        style={{
          height: 3 * gridSize,
        }}
        className={
          "flex flex-col items-center justify-center border-t-2 border-opacity-10 text-center"
        }
      >
        <Typography font={"futura"} size={18} letterSpacing={'0.8px'}>
          {store.full_name}
        </Typography>
        <Typography
          font={"helvetica-now"}
          size={14}
          lineHeight={"19px"}
          className={"mt-1"}
          style={{
            whiteSpace: 'pre-wrap'
          }}
          letterSpacing={'0.7px'}
        >
          {store.address}
        </Typography>
      </div>

      {
        isMobile &&
        (sessionType !== SESSION_TYPES.CLEAN_AND_CARE_EXPERIENCE || isWrite) &&
        <QrSection gridSize={gridSize} session={session} />
      }
    </>
  );
}

function QrSection({
  gridSize,
  session,
}: {
  gridSize: number;
  session: Session;
}) {

  return (
    <div
      className={"flex flex-wrap w-full items-center justify-center gap-3.5 border-t-2 border-opacity-10"}
      style={{
        height: 3 * gridSize,
      }}
    >
      <AppleWalletButton session={session} />
      <GoogleWalletButton session={session} />
    </div>
  );
}

function Date({
  bookingStartDate,
  gridSize,
  timezone,
  locale,
}: {
  bookingStartDate: number;
  gridSize: number;
  timezone?: string;
  locale?: string;
}) {
  const { t } = useTranslation();

  return (
    <div
      className={
        "flex w-1/2 flex-1 items-center justify-center border-r-2 pl-2 pr-2 border-opacity-10"
      }
      style={{
        width: 4 * gridSize,
      }}
    >
      <Typography font={"futura"} size={24} lineHeight={1}>
        {bookingStartDate ? (
          simpleDateFormat(bookingStartDate, timezone, locale, true)
        ) : t('Estimated Start')}
      </Typography>
    </div>
  );
}

function Day({
  bookingStartDate,
  timezone,
  locale
}: {
  bookingStartDate: number;
  timezone?: string;
  locale?: string;
}) {
  return (
    <div
      className={"flex flex-1 items-center justify-center pl-2 pr-2 border-opacity-10"}
    >
      <Typography font={"futura"} size={24}>
        {simpleAmPmTimeFormat(bookingStartDate, timezone, locale)}
      </Typography>
    </div>
  );
}