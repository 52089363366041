import { gql } from "@apollo/client";

export const AVAILABLE_SESSION_LIST = gql`
  query AvailableSessionList(
    $session_type: SessionType!
    $date: UnixMs
    $store_id: ID!
    $experience_id: ID!
  ) {
    availableSessionList(
      session_type: $session_type
      date: $date
      store_id: $store_id
      experience_id: $experience_id
    ) {
      objects {
        start_date
        end_date
        available_slots
      }
    }
  }
`;

export const GET_PUBLIC_SESSIONS = gql`
  query GetAllSessions($searchOptions: SessionSearchOptions) {
    publicSessionList(searchOptions: $searchOptions) {
      count
      lastEvaluatedKey
      objects {
        id
        type
        store_id
        experience_id
        created_date
        updated_date
        booking {
          type
          start_date
          end_date
        }
      }
    }
  }
`;