import { useMemo } from "react";
import { useMutation } from "@apollo/client";
import { Session } from "../data/types";
import { CREATE_APPLE_PASS } from "../data/gql/queries/user";
// @ts-expect-error -- the vite svg loader expects the "?react" suffix but ts doesn't know what it is
import ApplePassButton from "../assets/images/enUS_add_to_apple_wallet_wallet-button.svg?react";
import Loading from "./loading";


export default function AppleWalletButton({ session }: { session: Session }) {
  const [createApplePass, { loading }] = useMutation(CREATE_APPLE_PASS);

  const handleClick = async () => {
    if (loading) return;
    const res = await createApplePass({
      variables: {
        object: {
          session_id: session.id
        }
      }
    });
    const passUrl = res.data?.createApplePass?.passUrl;

    if (passUrl) {
      if (!window.open(passUrl)) {
        window.location.href = passUrl
      }
    }
  }

  const isIos = useMemo(() => {
    // return true if in dev mode
    if (process.env.NODE_ENV === "local") {
      return true;
    }
    return navigator.userAgent.match(/(iPhone|iPod|iPad)/i);
  }, []);

  return isIos
    ? loading ? <Loading/> : <div onClick={handleClick}><Icon /></div> 
    : null;
}

function Icon() {
  return (
    <ApplePassButton/>
  );
}
