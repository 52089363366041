import React from "react";
import { createPortal } from "react-dom";
import { motion, AnimatePresence } from "framer-motion";

import {DEFAULT_EASE, DEFAULT_DURATION, cn} from "@lib/shared";
import CloseButton from "./close-button";

const fade = {
  hidden: {
    opacity: 0,
    transition: {
      ease: DEFAULT_EASE,
      duration: DEFAULT_DURATION / 2,
    },
  },

  show: {
    opacity: 1,
    transition: {
      ease: DEFAULT_EASE,
      duration: DEFAULT_DURATION / 2,
    },
  },
};

const moveYReverse = {
  hidden: {
    y: "100%",
    transition: {
      ease: DEFAULT_EASE,
      duration: DEFAULT_DURATION,
    },
  },
  show: {
    y: "0%",
    transition: {
      ease: DEFAULT_EASE,
      duration: DEFAULT_DURATION,
    },
  },
};

interface ModalProps {
  className?: string;
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  showClose?: boolean;
  blurBg?: boolean;
  dissmissable?: boolean;
}

const Modal = ({ children, isOpen = false, onClose, blurBg = true, showClose = true, dissmissable, className }: ModalProps) => {

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <div className={cn('fixed top-0 left-0 z-[50] overflow-hidden w-screen h-screen', className)}>
          <motion.div
            className="absolute top-0 right-0 bottom-0 left-0"
            style={{
              backdropFilter: blurBg ? "blur(10px)" : 'none',
              backgroundColor: blurBg ? 'rgba(0, 0, 0, 0.60)' : 'rgba(0, 0, 0, 0.80)'
            }}
            onClick={(showClose || dissmissable) ? onClose : undefined}
            variants={fade}
            initial="hidden"
            animate="show"
            exit="hidden"
          />

          <motion.div
            className="absolute right-0 bottom-0 left-0 bg-nearwhite"
            variants={moveYReverse}
            initial="hidden"
            animate="show"
            exit="hidden"
            transformTemplate={({ y }) => `translateY(${y})`}
          >
            {showClose && <div
              className="absolute"
              style={{
                top: 40,
                right: 40
              }}
            >
              <CloseButton
                width={27}
                height={27}
                style={{
                  width: 60,
                  height: 60,
                }}
                onClick={onClose}
              />
            </div>}
            {children}
          </motion.div>
        </div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default Modal;
