import { useMemo } from "react";
import { useMutation } from "@apollo/client";
import { Session } from "../data/types";
import { CREATE_GOOGLE_PASS } from "../data/gql/queries/user";
// @ts-expect-error -- the vite svg loader expects the "?react" suffix but ts doesn't know what it is
import GooglePassButton from "../assets/images/enUS_add_to_google_wallet_wallet-button.svg?react";
import Loading from "./loading";

export default function GoogleWalletButton({ session }: { session: Session }) {
  const [createGooglePass, { loading }] = useMutation(CREATE_GOOGLE_PASS);

  const handleClick = async () => {
    if (loading) return;
    const res = await createGooglePass({
      variables: {
        object: {
          session_id: session.id
        }
      }
    });
    const passUrl = res.data?.createGooglePass?.passUrl;

    if (passUrl) {
      if (!window.open(passUrl)) {
        window.location.href = passUrl
      }
    }
  }

  const isAndroidOrWindows = useMemo(() => {

    // return true if in dev mode
    if (process.env.NODE_ENV === "local") {
      return true;
    }
    return navigator.userAgent.match(/(windows phone)/i) || navigator.userAgent.match(/(android)/i);
  }, []);


  if (isAndroidOrWindows) return loading ? <Loading/> : <div onClick={handleClick}><Icon /></div> 

  return null;
}

function Icon() {
  return (
    <GooglePassButton/>
  );
}
