import { t } from "i18next";
import Typography from "@components/typography.tsx";
import Status from "@components/status.tsx";
import BottomButton from "@components/bottom-button.tsx";
import React, { useEffect, useMemo, useState } from "react";
import { useGuestBooking, useGuestBookForm } from "./guest-sign-in.hooks.ts";
import { useNavigate } from "react-router-dom";
import { GuestForm } from "./guest-form";
import { SessionAlreadyExistsModal } from "./session-already-exists.modal.tsx";

export default function GuestSignIn() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { handleGuestBooking, alreadyHasSession } = useGuestBooking();

  const {
    email, setEmail,
    name, setName,
    privacyChecked, setPrivacyChecked,
    isReady
  } = useGuestBookForm();

  useEffect(() => {
    if (alreadyHasSession) {
      setShowModal(true);
    }
  }, [alreadyHasSession]);

  function handleContinue() {
    if (!isReady) {
      return;
    }

    handleGuestBooking({ g_email: email, preferred_name: name }).then(() => {
      // nice
    });
  }

  function onDismiss() {
    setShowModal(false)
    navigate('/intro')
  }

  return (
    <Status
      containerClassName={'flex flex-col flex-1'}
      outside={(
        <BottomButton
          fullWidth
          disabled={!isReady}
          onClick={handleContinue}
        >
          {t("Continue")}
        </BottomButton>
      )}
    >
      <div className={'mx-4 pt-6'}>
        <Typography className={"font-medium text-nearblack"} size={24}>
          {t("Guest Booking")}
        </Typography>

        <div className={'mt-6'}>
          <Typography className={"text-nearblack"} size={15}>
            {t("You'll be Notified Via Email Upon Booking")}
          </Typography>
        </div>

        <GuestForm
          name={name}
          onNameChange={(value) => setName(value)}
          email={email}
          onEmailChange={(value) => setEmail(value)}
          privacyChecked={privacyChecked}
          onPrivacyChange={(value) => setPrivacyChecked(value)}
        />
      </div>

      <SessionAlreadyExistsModal
        open={showModal}
        onAccept={onDismiss}
      />
    </Status>
  )
}

