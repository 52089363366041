import {useEffect, useState} from "react";

function getItem<T>(key: string, defaultValue?: T): T | undefined {
  const item = localStorage.getItem(key);

  if (item) {
    try {
      return JSON.parse(item);
    } catch (e) {
      return defaultValue;
    }
  }

  return defaultValue;
}

function setItem<T>(key: string, value: T) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function useStorage<T>(key: string, defaultValue: T): [T | undefined, (value: T) => void] {
  const [value, setValue] = useState<T | undefined>(() => getItem<T>(key, defaultValue));

  useEffect(() => {
    getItem<T>(key, defaultValue)
  }, [key, defaultValue]);

  const updateValue = (value: T) => {
    setItem(key, value);
    setValue(value);
  }

  return [value, updateValue];
}

export function getLocalStorageItemString(key: string) {
  const value = parseValue(window.localStorage.getItem(key));
  if (value) return `${value}`
  return value;
}

export function parseValue(value: string | null) {
  try {
    if (!value) return null;
    return JSON.parse(value);
  } catch (err) {
    return value;
  }
}

export default {
  getItem,
  setItem,
}